import React from 'react';
import bgImg from '../../assets/imgs/business-scene-with-nature.png';
import colabImg from '../../assets/imgs/collaboration-image.png';
import circleImg from '../../assets/imgs/economy-circle-image.png';
import ticketImg from '../../assets/imgs/ticket-flight-image.png';
import boardingGateImg from '../../assets/imgs/boarding-gate-image.png';
import airplaneImg from '../../assets/imgs/airplane-image.png';

interface HeadingProps {
  text: string;
}

const Heading: React.FC<HeadingProps> = ({ text }) => {
  return (
    <h1 className="mt-20 text-5xl font-extrabold text-right text-cyan-400 leading-[87px] max-md:mt-10 max-md:max-w-full max-md:text-4xl">
      {text}
    </h1>
  );
};

interface CardProps {
  imageSrc: string;
  title: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ imageSrc, title }) => {
  return (
    <div className="flex flex-col items-center justify-center sm:min-h-[180px] sm:min-w-[150px] min-h-[150px] min-w-[165px] px-4 py-4 bg-slate-800 rounded-[36px] max-md:px-5 max-sm:w-unset">
      <img loading="lazy" src={imageSrc} className="w-[48px]" alt="" />
      <div className="mt-4">{title}</div>
    </div>
  );
};

const cardsData: CardProps[] = [
  {
    imageSrc: colabImg,
    title: (
      <>
        <span className="font-bold">יצירת</span>
        <br />
        <span className="font-bold">שיתופי פעולה</span>
      </>
    ),
  },
  {
    imageSrc: circleImg,
    title: (
      <>
        <span className="font-bold">הדרכה וליווי</span>
        <br />
        <span className="font-bold">על ידי מומחי</span>
        <br />
        <span className="font-bold">התיירות במשק</span>
      </>
    ),
  },
  {
    imageSrc: ticketImg,
    title: (
      <>
        <span className="font-bold">מערכת ואתר</span>
        <br />
        <span className="font-bold">לסוכן נסיעות עם</span>
        <br />
        <span className="font-bold">חיבור לכל מערכות</span>
        <br />
        <span className="font-bold">התיירות</span>
      </>
    ),
  },
  {
    imageSrc: boardingGateImg,
    title: (
      <>
        <span className="font-bold">בית לסוכני</span>
        <br />
        <span className="font-bold">הנסיעות</span>
        <br />
        <span className="font-bold">בישראל</span>
      </>
    ),
  },
  {
    imageSrc: airplaneImg,
    title: (
      <>
        <span className="font-bold">רכש טיסות</span>
        <br />
        <span className="font-bold">וסידורי קרקע</span>
        <br />
        <span className="font-bold">במגוון יעדים בארץ</span>
        <br />
        <span className="font-bold">ובעולם</span>
      </>
    ),
  },
];

const ServicesComponent = () => {
  return (
    <section id={'services'} className="relative !overflow-visible">
      <img
        src={bgImg}
        alt="business-scene-with-nature"
        className="object-cover absolute top-0 left-0 bottom-0 left-0 z-[-1] w-[500px] max-sm:mt-9"
        style={{ clipPath: 'ellipse(60% 50% at 40% 60%)' }}
      />
      <Heading text="השירותים שלנו" />
      <div className="flex flex-wrap flex-row-reverse max-sm:flex-col max-sm:mt-[280px] mx-auto gap-5 justify-center self-end max-w-[1100px] w-[90vw] mt-12 text-base font-extrabold leading-4 text-center text-white max-lg:flex-wrap max-md:justify-start max-lg:justify-between max-md:mt-10">
        {cardsData.map((card, index) => (
          <div key={index} className="flex flex-col justify-center max-sm:items-center">
            <Card imageSrc={card.imageSrc} title={card.title} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServicesComponent;
