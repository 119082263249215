import * as React from 'react';
import img from '../../assets/imgs/girl-on-beach-image.png';

function AboutUsComponent() {
  return (
    <section
      id={'about-us'}
      className="mt-14 w-full max-md:mt-10 max-md:max-w-full"
    >
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col ml-5 w-3/5 max-md:ml-0 max-md:w-full">
          <div className="flex flex-col px-5 text-right text-black max-md:mt-10 max-md:max-w-full">
            <h2 className="self-start text-5xl font-extrabold leading-[40px] max-md:max-w-full max-md:text-4xl">
              קצת עלינו
            </h2>
            <div className="mt-2 text-base font-light leading-7 max-md:max-w-full">
              <p>
                סקיי 360 הינה חברה העוסקת בתחום תעופה,תיירות נכנסת ופיתוח מערכות
                ענן הנותנות פתרונות טכנולוגיים בעולם התיירות.
              </p>
              <p>
                <span className="font-extrabold">תעופה</span> - חברתנו מתתחה
                בהפעלת טיסות ציארטר ליעדי אירופה והמזרח התיכון. הפעלת טיסות
                מיוחדות לבקשת הלקוח משדה התעופה נתב&quot;ג ושדה התעופה חיפה.
                הפעלת טיסות מיוחדות לאירועי ספורט בעולם. מכירת קבוצות לועדים,
                ארגונים וחברות מובילות במשק. בשנת 2023 חברתנו החזירה לפעילות
                מסחרית את שדה התעופה חיפה לאחר 4 שנים ללא פעילות חברתנו הפעילה
                בשיתוף חברת התעופה המלטזית יוניברסל אייר טיסות לקפריסין בחודשים
                יוני-אוקטובר, כ-18 טיסות שבועיות כולל טיסות חילוץ לשגרירות קנדה
                בישראל בזמן המלחמה. הטיסות בוצעו עם מטוסים צרי גוף מסוג Dash8
                -Q100המתאימים לפעילות במסלולים הקצרים של שדה התעופה חיפה .
              </p>
              <p>
                <span className="font-bold">תיירות נכנסת </span>- סקיי 360 מתמחה
                בתיירות נכנסת של צליינים מאירופה ואחראית על כל שירותי ההטסה
                ,מלונות ושירותי הקרקע שלהם לרבות מדריכים וכניסות לאתרים
              </p>
              <p>
                <span className="font-bold">טכנולוגיה</span> - לחברה מספר מערכות
                חדשניות שהוקמו עקב צורך וכאב בעולם התיירות. בין מוצרנו ניתן
                למצוא: מערכת סוכני נסיעות (B2B) , מערכת לרישום קבוצות, הקמת אתרי
                סחר בתיירות, אתרי מלונות, מערכת סיטונאית לניהול מלאי ועוד.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-2/5 max-md:ml-0 max-md:w-full">
          <img
            loading="lazy"
            src={img}
            alt={'about us img'}
            className="grow mt-7 w-full aspect-[0.72] max-md:mt-10 max-md:max-w-full"
          />
        </div>
      </div>
    </section>
  );
}

export default AboutUsComponent;
