

const appConfig = {
    "siteConfig": {
        "defaultLang": "he",
        "languages": [
            {
                "code": "he",
                "dir": "rtl",
                "name": "עברית",
            },
            // {
            //     "code": "en",
            //     "dir": "ltr",
            //     "name": "English",
            // },
            {
                "code": "ar",
                "dir": "rtl",
                "name": "عربيه",
            },
        ],
        "name": "Sky 360",
        "domain": "www.sky360.co.il"
    },
    "theme": {
        "primary": "#00CCFF",
    },
};
export default appConfig;