import React from 'react';

const PrivateFlightsProductList = (Props) => {
  return (
    <div className="destination-wrapper">
      <div className="destination-wrapper">
        {Props.products.map((destination) => {
          return (
            <div className="destination" key={destination.text}>
              <div className="text">{destination.text}</div>
              <img
                src={`/private-flights/${destination.logo}`}
                className="logo"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PrivateFlightsProductList;
