import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import './product-header.less';
// @ts-ignore
import logo from './assets/sky3601.png';
// @ts-ignore
import ipad from './assets/Ipad.png';
import translationsHe from '../../translations/translationsHe';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

const PRIVATE_FLIGHTS_URL = '/private-flights/';
const links = translationsHe.data.translation.productPage.header.links;
const header = translationsHe.data.translation.productPage.header;

const ProductHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [ignore, setIgnore] = useState(false);
  const isPrivateFlights = location.pathname === PRIVATE_FLIGHTS_URL;
  const [searchParams] = useSearchParams();
  const [isScrolledToTop, setIsScrolledToTop] = useState(true);

  const [elementVisible, setElementVisible] = useState<string>(
    searchParams.get('section') || isPrivateFlights
      ? 'private-flights'
      : 'product-header'
  );

  const handleScrollToTop = () => {
    setIsScrolledToTop(window.scrollY === 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollToTop);
    return () => {
      window.removeEventListener('scroll', handleScrollToTop);
    };
  }, []);

  const scrollFunction = (id: string) => {
    if (id === 'private-flights' && !isPrivateFlights) {
      navigate(PRIVATE_FLIGHTS_URL);
      setElementVisible(id);
    }
    if (id === 'private-flights') return;

    setIgnore(true);
    navigate('/');
    setElementVisible('');
    setTimeout(() => {
      setElementVisible(id);
      setIgnore(true);
      navigate(`/?section=${id}`);
      const myDiv = document.getElementById(id);
      myDiv?.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => setIgnore(false), 2000);
    }, 100);
  };

  useEffect(() => {
    function handleScroll() {
      if (ignore) return;
      if (location.pathname === PRIVATE_FLIGHTS_URL) return;
      const contactUs = document.getElementById('contact-us');
      const aboutUs = document.getElementById('about-us');
      const product = document.getElementById('product');
      const productHeader = document.getElementById('product-header');
      if (contactUs) {
        const top = contactUs.getBoundingClientRect().top;
        if (top >= -200 && top <= window.innerHeight)
          setElementVisible(contactUs.id);
      }
      if (aboutUs) {
        const top = aboutUs.getBoundingClientRect().top;
        if (top >= -200 && top <= window.innerHeight)
          setElementVisible(aboutUs.id);
      }
      if (product) {
        const top = product.getBoundingClientRect().top;
        if (top >= -200 && top <= window.innerHeight)
          setElementVisible(product.id);
      }
      if (productHeader) {
        const top = productHeader.getBoundingClientRect().top;
        if (top >= -200 && top <= window.innerHeight)
          setElementVisible(productHeader.id);
      }
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ignore, location.pathname]);

  return (
    <div
      className={`${isPrivateFlights ? 'product-header-private-flight' : ''} ${
        isScrolledToTop ? '' : 'product-header-scrolled'
      } product-header`}
      id="product-header"
    >
      <div className="content-wrapper links-wrapper">
        <div className="header-fixed">
          <div className="links">
            <Row gutter={[24, 24]}>
              <Col className={'top-header'} span={20}>
                <Row gutter={[24, 24]}>
                  {Object.keys(links).map((key: string, i) => {
                    return (
                      <Col
                        className={`link ${
                          key === elementVisible &&
                          (location.pathname === '/' || isPrivateFlights) &&
                          'link-selected'
                        }`}
                        key={i}
                        onClick={() => scrollFunction(key)}
                      >
                        {links[key]}
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col span={4} xs={24} md={4}>
                <Link className="logo" to="/">
                  <img src={logo} className="logo" />
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <div className="header-lower content-wrapper">
        {/*<div className={"main-desc"}>*/}
        {/*    <div>{header.sky_agency}</div>*/}
        {/*    <div>{header.sky_course}</div>*/}
        {/*    <div>{header.sky_tech}</div>*/}
        {/*</div>*/}
        {/*{!isPrivateFlights && <img src={ipad} className="ipad" />}*/}
        {/*<div className="button-text-wrapper">*/}
        {/*  <div className="text">*/}
        {/*    {isPrivateFlights ? header.privateFlightsText : header.text }*/}
        {/*  </div>*/}
        {/*  /!*<Button className="btn" icon={<AiOutlineArrowLeft />}>*!/*/}
        {/*  /!*  {header.button}*!/*/}
        {/*  /!*</Button>*!/*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default ProductHeader;
