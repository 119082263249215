import React from 'react';
import AboutUsComponent from '../../widgets/aboutUs/AboutUsComponent';
import BusinessModelComponent from '../../widgets/businessModel/BusinessModelComponent';
import ChooseSystemComponent from '../../widgets/chooseSystem/ChooseSystemComponent';
import ClientsListComponent from '../../widgets/ClientsList/ClientsListComponent';
import FooterComponent from '../../widgets/footer/FooterComponent';
import HeaderComponent from '../../widgets/header/HeaderComponent';
import HeroComponent from '../../widgets/hero/HeroComponent';
// import PartnersInfoRow from '../../widgets/partnersInfo/PartnersInfoRow';
import SystemPromotionCardComponent from '../../widgets/promotionCard/SystemPromotionCardComponent';
import RegisterFormComponent from '../../widgets/registerForm/RegisterFormComponent';
import ServicesComponent from '../../widgets/services-section/ServicesComponent';
import SubscriptionPlansComponent from '../../widgets/subscriptionPlans/SubscriptionPlansComponent';
import TechnologyComponent from '../../widgets/technology/TechnologyComponent';
import ModelsComponent from '../../widgets/models-section/ModelsComponent';

export const Sky360LandingPage: React.FC = () => {
  const componentKey = 'sky360-landing-page';
  // const register_agent_url = 'https://bo.sky360.co.il/agent-registration/agent360';
  const register_agent_url = 'https://www.sky360.co.il/reg/agent/MNST';
  return (
    <div className={componentKey}>
      <HeaderComponent registerUrl={register_agent_url} />
      <HeroComponent />
      <SubscriptionPlansComponent registerUrl={register_agent_url} />
      <AboutUsComponent />
      {/* <PartnersInfoRow /> */}
      <ServicesComponent />
      <TechnologyComponent />
      <ModelsComponent />
      <BusinessModelComponent />
      <ChooseSystemComponent registerUrl={register_agent_url} />
      <SystemPromotionCardComponent registerUrl={register_agent_url} />
      <ClientsListComponent />
      <RegisterFormComponent />
      <FooterComponent />
    </div>
  );
};
