import React from 'react';
import WebsiteInfoFullRowComponent from './WebsiteInfoFullRowComponent';

const ModelsComponent = () => {
  return (
    <section id="models">
      <WebsiteInfoFullRowComponent
        textSectionContent={`מנוע חיפוש לטיסות חבילות ומלונות באינטגרציה עם המערכות המובילות בעולם(אלפ,אמדאוס,רייטהאוק)
      הקמת חבילות משולבות טיסה מלון ואטרקציות
      דוחות עמלה לסוכן+דוחות אופרציה לסיטונאים
      מערכת לידים ייחודית
      עיצוב דינמי וחדשני המותאם לעסק 
      שליחת הצעת מחיר ללקוח לסגירה ישירה הכוללת רענון בזמן אמת ללקוח ללא מגע יד אדם
      מודול סליקת לקוח עם פיצול אשראי ועוד
    `}
        buttonSectionContent={'מערכת לסוכן (B2B)'}
        backgroundColor={'bg-gradient-to-r from-[#6ECDEC] to-[#4CD9ED]'}
      />
      <WebsiteInfoFullRowComponent
        textColor={`text-[#fff]`}
        textSectionContent={`אתר אינטרנט לסוכן נסיעות עצמאי כולל לוגו וצבעים לפי מיתוג העסק
    האתר מאפשר הצגת כלל המוצרים במגוון תבניות מכירה דינמית לטיסות,מלונות וחבילות נופש.
    מנוע חיפוש לטיסות חבילות ומלונות בכל העולם
    הצגת חבילות משולבות טיסה מלון ואטרקציות 
    מודול סליקת לקוח עם פיצול אשראי ועוד
    סינכון מלא עם המערכת לסוכן ומעבר בין אתר B2B לאתר B2C בקליק`}
        buttonSectionContent={`אתר אינטרנט לכל סוכן (B2C)`}
        backgroundColor={'bg-gradient-to-r from-[#2a6180b7] to-[#273B42]'}
        buttonColor={`bg-[#0CF]`}
      />
      <WebsiteInfoFullRowComponent
        textSectionContent={`הקמת דפי נחיתה עבור חבילת נופש - טיסה מלון העברות ואטרקציות
    הקמת קבוצת עובדים עם סבסוד וזיהוי העובד
    אפשרות לסליקה עם פיצול אשראי
    ניהול הזמנות והפקת דוחות לספקים
    ניהול הזמנות חכם וניהול דוחות ספקים ותשלומים`}
        buttonSectionContent={`מערכת לרישום קבוצות`}
        backgroundColor={'bg-gradient-to-r from-[#CEF4FA] to-[#6ECDEC]'}
      />
      <WebsiteInfoFullRowComponent
        textColor={`text-[#fff]`}
        textSectionContent={` מערכת לניהול לקוחות בצורה אוטומטית אשר מאפשרת מענה למאות לקוחות מדי יום
    גישה מהירה למידע עבור לקוחות באמצעות בוט
    מרכזיה חכמה כוללת תפריט קולי וניתוב לקוחות לוואטסאפ העסקי
    מענה ללקוח על ידי נציג אנושי 24/7.`}
        buttonSectionContent={`אוטמוציה לשירות לקוחות`}
        backgroundColor={'bg-gradient-to-r from-[#4CD9ED] to-[#326CD4]'}
      />
    </section>
  );
};

export default ModelsComponent;
