import * as React from 'react';
import CheckmarkIcon from '../../icons/CheckmarkIcon';

interface PricingCardProps {
  title: string;
  description: string[];
  buttonText: string;
  registerUrl: string;
}

const PricingCard: React.FC<PricingCardProps> = ({
  title,
  description,
  buttonText,
  registerUrl,
}) => {
  return (
    <div className="flex flex-col grow max-md:mt-10">
      <div className="flex flex-col p-2 bg-sky-300 rounded-[50px] min-h-[400px]">
        <div className="flex flex-col text-right text-slate-800">
          <h2 className="p-3 self-center text-2xl font-extrabold text-right text-white">
            {title}
          </h2>
          <div className="flex flex-col items-center gap-3.5 px-8 max-lg:px- text-base font-bold">
            {description.map((text, index) => (
              <div
                key={index + '_' + text}
                className="flex items-start justify-start w-full"
              >
                <CheckmarkIcon className="aspect-square min-w-[20px] ml-2" />

                <p className="flex-auto text-base font-semibold leading-5 text-right text-slate-800 !mb-0">
                  {text}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <a
        onClick={() => window.open(registerUrl, '_blank')}
        className="z-10 flex justify-center items-center p-4 mt-[-50px] text-xl font-extrabold !text-white bg-slate-800 rounded-[30px] max-md:px-5 max-md:mt-10 max-md:mb-2.5 max-md:text-4xl"
      >
        {buttonText}
      </a>
    </div>
  );
};

const pricingData: Omit<PricingCardProps, 'registerUrl'>[] = [
  {
    title: 'SKY 360',
    description: [
      'מערכת לסוכן תיירות הכוללת טיסות וחבילות נופש,טיסות סדירות וממשק בתי מלון(B2B)',
      'אתר מכירות למשווק (B2C) הכולל מיתוג.',
      'ניהול הזמנות ויצירת לידים',
      'סליקה עצמאית של הסוכנות',
      'הקמת חבילות משולבות טיסה מלון ואטרקציות',
      'דוחות לסוכן ולספקים',
    ],
    buttonText: 'לרישום חינם',
  },
  {
    title: 'AGENT 360 PRO',
    description: [
      'מערכת לסוכן תיירות הכוללת טיסות וחבילות נופש,טיסות סדירות וממשק בתי מלון(B2B+B2C)',
      'אתר מכירות ממותג למשווק (B2C) הכולל יצירת ייחודיות .',
      'ניהול הזמנות ויצירת לידים',
      'דוחות עמלה לסוכן',
      'סליקת לקוח עם פיצול אשראי',
    ],
    buttonText: 'לרישום חינם',
  },
  {
    title: 'AGENT 360',
    description: [
      'אתר ומערכת לסוכן תיירות הכוללת טיסות וחבילות נופש,טיסות סדירות וממשק בתי מלון (B2B+B2C)',
      'ניהול הזמנות ויצירת לידים',
      'דוחות עמלה לסוכן',
      'סליקת לקוח עם פיצול אשראי',
    ],
    buttonText: 'לרישום חינם',
  },
];

function ChooseSystemComponent({ registerUrl }: { registerUrl: string }) {
  return (
    <section className="mt-12 max-md:mt-10 max-md:max-w-full">
      <h1 className="self-start text-5xl font-extrabold text-right text-black leading-[37px] max-md:max-w-full max-md:text-4xl max-md:leading-10">
        בחרו את המערכת שלכם
      </h1>
      <div className="flex items-start gap-5 max-md:flex-col max-md:gap-0">
        {pricingData.map((card, index) => (
          <PricingCard key={index} {...card} registerUrl={registerUrl} />
        ))}
      </div>
    </section>
  );
}

export default ChooseSystemComponent;
