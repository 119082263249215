import React, { memo, useEffect, useState } from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import './course.less';
import {Row, Col, Button, Layout,} from 'antd';

import apiClient from '@skygroup/shared/API/apiClient';
import endpoints from '@skygroup/shared/API/endpoints';
import SpinLoader from '@skygroup/shared/components/spin-loader/SpinLoader';
import './course.less';

import CourseHeader from "./widgets/course-header/CourseHeader";
import CourseInfo from "./widgets/course-info/CourseInfo";
import Certificate from "./widgets/certificate/Certificate";
import CourseRegisterForm from "./widgets/course-register-form/CourseRegisterForm";
// @ts-ignore
import logo from "./../../assets/logo-sky.png";
import CourseExperts from './widgets/course-experts/CourseExperts';
import CourseFinishSuccessfully from './widgets/course-finish-successfully/CourseFinishSuccessfully';
import ExpertsSlider from './widgets/experts-slider/ExpertsSlider';
import CourseHeader2 from './widgets/course-header-2/CourseHeader';
import MainVideo from "./widgets/main-video/MainVideo";
import CoursePaymentForm from "./widgets/course-payment-form/CoursePaymentForm";
import {useCourseCycles} from "../lead-landing-page/hooks/useWebinarCycles";
import WebinarRegisterForm from "../lead-landing-page/widgets/webinar-register-form/TrainingRegisterForm";
import TrainingPaymentForm from "../lead-landing-page/widgets/training-payment-form/TrainingPaymentForm";

const Course = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [totalCount, setTotalCount] = useState(0);

    const navigate = useNavigate();
    const componentKey = 'course-landing-page';
    const [currentCourse, setCurrentCourse] = useState<any>();
    const {courseKey } = useParams();

    const leadSecret = searchParams.get("secret");

    const { data: cycles, isLoading: isLoadedCycles } = useCourseCycles(courseKey);

    useEffect(() => {
        if (!isLoadedCycles) {
            if (cycles && courseKey && cycles[courseKey] && cycles[courseKey].status !== "close") {
                setCurrentCourse(cycles[courseKey]);
            } else {
                window.location.href = "https://www.sky360.co.il";
            }
        }
    }, [cycles, courseKey, isLoadedCycles]); // Dependencies array includes anything that, upon changing, should trigger this effect


    return (
        <div className={componentKey}>
            <CourseHeader2 />
            {/*<CourseHeader/>*/}
            {/*{ leadSecret && <CoursePaymentForm secret={leadSecret} /> }*/}
            { leadSecret && <TrainingPaymentForm secret={leadSecret} /> }

            {/*<ExpertsSlider />*/}
            {/*<MainVideo />*/}
            <CourseInfo/>
            <CourseExperts />
            <CourseFinishSuccessfully />
            {/*<Certificate/>*/}
            { !leadSecret && <WebinarRegisterForm courseCycleId={courseKey} currentCourse={currentCourse}/> }
            <div className="footer">
                <img className="logo"  src={`/assets/sky_360.png`} alt="Sky 360"/>
            </div>
        </div>
    );
};

export default memo(Course);