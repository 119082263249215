
import apiClient from '@skygroup/shared/API/apiClient';
import endpoints from "b2c/src/utils/endpoints";

export const loadPaymentForm = async (
    formData: any,
    setIsLoading: any,
    secret: string
) => {
    setIsLoading(true);
    return apiClient
        .post(  `/b2c/lead/loadPaymentForm?secret=${secret}`, formData)
        .then((res) => {
            return res;
        })
        .finally(() => {
            setIsLoading(false);
        });
};

export const loadTransaction = (formData: {transaction_id: string, secret: string}, setIsLoading:any) => {

    setIsLoading(true);
    return apiClient
        .get('/b2c/lead/transaction-status' + `?transaction_id=${formData.transaction_id}&secret=${formData.secret}`)
        .then((res) => { return res })
        .finally(() => {
            setIsLoading(false);
        });
}