import * as React from 'react';
import skyLogo from '../../assets/imgs/sky-logo.png';

interface NavItemProps {
  children: React.ReactNode;
  className?: string;
  sectionId?: string;
}

const NavItem: React.FC<NavItemProps> = ({
  children,
  sectionId,
  className = '',
}) => (
  <a href={`#${sectionId}`} className={`my-auto ${className}`}>
    {children}
  </a>
);

const navItems = [
  { label: 'עמוד הבית', className: 'font-extrabold', href: 'plans' },
  { label: 'מי אנחנו', href: 'about-us' },
  { label: 'מוצרים', href: 'partners' },
  { label: 'שירות', href: 'services' },
  { label: 'טכנולוגיה', href: 'technology' },
  { label: 'מודלים', href: 'models' },
  { label: 'בין לקוחותינו', href: 'clients' },
  { label: 'צור קשר', className: 'grow', href: 'contact-us' },
];

function HeaderComponent({ registerUrl }: { registerUrl: string }) {
  return (
    <section>
      <nav className="bg-[#FFF] flex gap-5 justify-between self-center w-full text-base text-black max-w-[1418px] max-md:flex-wrap max-md:max-w-full mt-5 mb-10 mx-auto z-101 ">
        <img
          loading="lazy"
          src={skyLogo}
          alt="Company logo"
          className="aspect-[3.45] w-[248px] sm:hidden mx-auto"
        />
        <div className="flex gap-5 my-auto max-md:flex-wrap max-md:max-w-full">
          {navItems.map(({ label, className = '', href = '' }) => (
            <NavItem key={label} className={className} sectionId={href}>
              {label}
            </NavItem>
          ))}
        </div>
        <div className="flex gap-5 justify-between font-extrabold max-sm:flex-col">
          <img
            loading="lazy"
            src={skyLogo}
            alt="Company logo"
            className="aspect-[3.45] w-[248px] max-sm:hidden"
          />
          <a
            onClick={() => window.open(registerUrl, '_blank')}
            className="justify-center px-4 py-2.5 my-auto bg-sky-300 rounded-2xl border border-black border-solid max-sm:px-2 max-sm:py-1 max-sm:rounded-xl"
          >
            לרישום חינם
          </a>
        </div>
      </nav>
    </section>
  );
}

export default HeaderComponent;
