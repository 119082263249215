const translationsHe = {
    "status": "success",
    "data": {
        "lang": "he",
        "app": "skygroup",
        "translation": {
            "productPage":{
                "colors":['#32D6FF', '#3C41DF', '#51497A', '#82B6EC', '#FF6576', '#e566ff'],
                "header": {
                    "links":{
                        "product-header":'עמוד הבית',
                        // "about-us":'מי אנחנו',
                        "product":'המוצרים שלנו',
                        "contact-us":'צור קשר',
                        // "private-flights":"טיסות פרטיות",
                    },
                    "text": "",
                    "sky_agency": "Agent 360 - הבית של סוכני התיירות בישרא",
                    "sky_course": "קורס סוכני ומשווקי תיירות מבית Sky 360",
                    "sky_tech": "פתרונות טכנולוגיים לענף התיירות",
                    "courses": "סקיי 360 - הבית של סוכני התיירות בישרא",
                    "privateFlightsText":"טיסות פרטיות מחיפה",
                    "button":"קרא עוד"
                },
                'product':{
                    "titleLarge":"המוצרים של Sky 360",
                    "titleSmall":"הכירו את המערכות החדשניות של עולם התיירות מקרוב",
                    "products":{
                        "skygroups":{
                            'logo':'logo-skygroups.svg',
                            'img':"IconSkyGroup.svg",
                            'name':"Sky Groups",
                            'texts':{
                                'c1':'מערכת לרישום קבוצות',
                                'c2':'הקמת דפי נחיתה עבור חבילת נופש - טיסה מלון העברות ואטרקציות',
                                'c22':'הקמת קבוצת עובדים עם סבסוד וזיהוי העובד',
                                'c3':"אפשרות לסליקה עם פיצול אשראי",
                                'c44':"ניהול הזמנות והפקת דוחות לספקים",
                                'c4':"ניהול הזמנות חכם וניהול דוחות ספקים ותשלומים",
                            }
                        },
                        "skyb2c":{
                            'logo':'logo-skyb2c.svg',
                            'img':"IconSkyb2c.svg",
                            'name':"Sky Web",
                            "texts":{
                                'c2':"אתר ללקוח הקצה המאפשר הצגה כלל המוצרים במגוון תבניות מכירה דינמית למלונות וטיסות וחבילות נופש",
                                'c1':"מנוע חיפוש חבילות ומלונות",
                                'c3':"קידום פרטני של מוצרים שונים",
                                'c4':"עיצוב דינמי וחדשני המותאם לעסק",
                                'c5':"מודול סליקת לקוח עם פיצול אשראי ועוד",
                            }
                        },
                        "sky360":{
                            'logo':'logo-sky360.svg',
                            'img':"IconSky360.svg",
                            "texts":{
                                'c1':'מערכת לניהול לקוחות בצורה אוטומטית אשר מאפשרת מענה למאות לקוחות מדי יום',
                                'c2':"גישה מהירה למידע עבור לקוחות באמצעות בוט ",
                                'c3':"מרכזיה חכמה כוללת תפריט קולי וניתוב לקוחות לוואטסאפ העסקי",
                                'c4':"ל בלקוח על ידי נציג אנושי 24/7. "
                            }
                        },
                        "skyacs":{
                            'logo':'logo-skyacs.svg',
                            'img':"IconSkyAcs.svg",
                            'name':"ACS",
                            "texts":{
                                "c1":"בוט ומענה אנושי לניהול לקוחות אוטומטי",
                                "c2":"מאפשרת מענה למאות לקוחות מדי יום",
                                "c3":"גישה מהירה למידע עבור לקוחות באמצעות בוט",
                                "c4":"מרכזיה חכמה - כוללת תפריט קולי וניתוב לקוחות לוואטסאפ העסקי",
                                "c5":"טיפול בלקוח על ידי נציג אנושי 24/7."
                            }
                        },
                        "skyacco":{
                            'logo':'logo-skyeco.svg',
                            'img':"IconSkyAcco.svg",
                            'name':"Sky Acco",
                            "texts":{
                                'c1':"מערכת ניהול למתחמי לינה - מלונות וצימרים",
                                'c2':'המערכת כוללת אתר ללקוח הקצה לרכישת לילות',
                                "c3":"עיצוב חדשני ומותאם לכל לקוח",
                                'c4':"במערכות ניהול מלאי חדרים, מחירונים ואטרקציות",
                                "c5":"אפשרות לייצר חבילות נופש ודילים לאירועים",
                                'c6':"אינטגרציה לאתר קופונים",
                            }
                        },

                        "skyagent":{
                            'logo':'logo-skyagent.svg',
                            'img':"IconSkyAgent.svg",
                            'name':"Sky Agent",
                            "texts":{
                                'c1':" מערכת למשווקים (קמעונאית עם אלפ)",
                                'c2':"מאפשרת הקמת חבילות משולבות טיסה מלון ואטרקציות",
                                'c3':"ניהול הזמנות ויצירת לידים",
                                'c4':"סליקת לקוח עם פיצול אשראי",
                                'c5':"אפשרות להקים אתר מכירתי למשווק",
                            }
                        },
                    },
                },
                'aboutUs':{
                    "rightTitle":"מי אנחנו",
                    'desc': "Sky 360 הינה חברה העוסקת בתעופה ופיתוח מערכות ענן הנותנות פתרונות טכנולוגיים בעולם התיירות. \n\n" +
                        "לחברה מספר מערכות חדשניות שהוקמו עקב צורך וכאב בעולם התיירות.  בין מוצרנו: מערכת סוכנים, מערכת לרישום קבוצות, הקמת אתרי שכר בתיירות, אתרי מלונות, מערכת סיטונאטית לניהול מלאי ועוד.",
                    'desc_flight': "בפעילות התעופה, חברתנו מתתחה בהפעלת טיסות ציארטר ליעדי אירופה והמזרח התיכון.\n" +
                        'הפעלת טיסות מיוחדות לבקשת הלקוח משדה התעופה נתב"ג ושדה התעופה חיפה. \n' +
                        "הפעלת טיסות מיוחדות לאירועי ספורט בעולם. \n" +
                        "מכירת קבוצות לועדים, ארגונים וחברות מובילות במשק. \n\n" +
                        `בשנת 2023 חברתנו החזירה לפעילות מסחרית את שדה התעופה חיפה לאחר 4 שנים ללא פעילות .
                        חברתנו הפעילה בשיתוף חברת התעופה המלטזית יוניברסל אייר טיסות לקפריסין החל מ22.6 ועד ל8.10 , 3 טיסות ביום כל ימות השבוע (לא כולל שבת )כולל טיסות חילוץ לשגרירות קנדה בישראל בזמן המלחמה.
                        הטיסות בוצעו עם מטוסים צרי גוף מסוג Dash8 -Q100
                        המתאימים לפעילות במסלולים הקצרים של שדה התעופה חיפה .`,
                    'btn':"קרא עוד",
                    "title":"Sky 360",
                    // 'subTitle':"החברה מספקת פתרונות טכנולוגים מתקדמים לעולם התיירות  -סיטונאים ולקמעונאים בארץ ובעולם",
                    'subTitle':"לחברה פעילויות בתחום התעופה ופיתוח מערכות טכנולוגיות בעולם התיירות",
                },
                'contactUs':{
                    'title':"צור קשר",
                    'subTitle':"צרו איתנו קשר ונתאים עבורכם את המערכת המתאימה ביותר בשבילכם",
                    'email':"דוא״ל",
                    "textArea":"כתבו לנו הודעה",
                    "phone":"טלפון",
                    "fullName":"שם מלא",
                    'button':"שליחה",
                    'fieldRequired':"שדה חובה"
                },
                "footer":{
                    'text':"כל הזכויות שמורות לsky360-. אין להשתמש בחומרי ותוכן האתר ללא אישור.",
                }
            },
            "trainingLandingPage": {
                "title": "הכשרה סוכני ומשווקי תיירות",
                "courseDetails": {
                    "when": {
                        "title": "מועד ההכשרה",
                    },
                    "structure": {
                        "title": "מתכונת ההכשרה",
                        "semiTitle": "4 ימי לימוד",
                        "content": {
                            "c1": "שיעור 1 - הכרת עולם התיירות וניתוח קהלים ויעדים",
                            "c2": "שיעור 2 - אסטרטגיה שיווקית ומכירה דרך שירות",
                            "c3": "שיעור 3 - רשתות חברתיות, קפמיינים והיבטים משפטיים",
                            "c4": "שיעור 4 - הכרת ורישום מערכת SKY 360 לסוכנים",
                        },
                        "bonus": {
                            "title": "בונוס",
                            "content1" : "חודש ראשון מתנה למערכת SKY 360",
                            "content2" : " לנרשמי ההכשרה תחת סוכנות הבית",

                        },
                    },
                    "duty": {
                        "title": "מה נדרש?",
                        "content": "השתתפות באופן סדיר בהרצאות ביצוע משימות והגשת עבודת גמר",
                    },
                    "promoteSection": {
                        "t1": "לעבוד בעולם התיירות!",
                        "t2": "בואו לעבוד בעולם התיירות עם התמיכה של הטובים ביותר!",
                    }
                },
                "forWho": {
                    "title": "למי מיועדת התכנית?",
                    "content": {
                        "c1":"למעוניינים ליצור הכנסה נוספת או ראשית בעולם התיירות",
                        "c2":"למעוניינים להשתלב כפרילנסרים תחת SKY 360",
                        "c3":"סוכני נסיעות המעוניינים לעבור למערכת המתקדמת ביותר למכירה  בעולם התיירות"
                    }
                },
                "finishSuccessfully":{
                    "title": "למסיימים את  ההכשרה בהצלחה",
                    "benefits":[
                        {
                            'title':'תינתן תעודת סוכן ומשווק תיירות מטעם חברת SKY360',
                            'img':"certification.png"
                        },
                        {
                            'title':'חודש ניסיון חינם במערכת  SKY360',
                            'img':"check_mark.png"
                        },
                        {
                            'title':'ליווי שוטף עם צוות המומחים בשנה הראשונה עד להצלחה',
                            'img':"check_mark.png"
                        },
                        // {
                        //     'title':'למתאימים ומעוניינים שילוב כשכירים עם תנאים מעולים בחברות המובילות במשק',
                        //     'img':"check_mark.png"
                        // },
                    ],
                },
                "payment": {
                    "title": "מחיר הכשרה",
                    "shah": "ש\"ח",
                    "content1": "הרישום להכשרה מסתיים בקרוב",
                    "content2": "מספר המקומות מוגבל",
                    "registerNow": "הירשמו עכשיו!",
                    "form": {
                        "title": "הירשמו עכשיו!",
                        "full_name":"שם מלא",
                        "email":"אימייל",
                        "phone":"טלפון",
                        "birthday":"תאריך לידה",
                        "moreInfo":"האם קיים ניסיון בעולם התיירות : כן\\לא. פרט : ",
                        "action": "שמור ועבור לתשלום",
                        "cycleChoose" :"מעוניין/ת להצטרף למחזור",
                        "radioSelection" :"מעוניינים בסוף הקורס להשתלב בתור",
                        "independent" :"עצמאי",
                        "employee" :"שכיר",
                        "onSuccess": "פרטיך נשמרו בהצלחה, הינך מועבר לתשלום",
                        // "onSuccess": "פרטיך נשלחו בהצלחה, הינך מועבר לתשלום",
                    },
                    "required":"יש להזין שדה חובה!"
                },
                "header": {
                    "title": "קורס סוכני ומשווקי תיירות",
                    'links': [
                        // {
                        //     'text': "לעבוד בענף התיירות",
                        //     'url': ""
                        // },
                        {
                            'text': "הקורס שלנו",
                            'url': "course-info"
                        },
                        {
                            'text': "המומחים שלנו",
                            'url': "course-experts"
                        },
                        // {
                        //     'text': "מערכת Agent360",
                        //     'url': "/"
                        // },
                        {
                            'text': "רישום להכשרה",
                            'url': "training-register-form"
                        },
                    ],
                },
            },
            "webinarLandingPage": {
                "title": "וובינר Agent 360",
                "courseDetails": {
                    "when": {
                        "title": "מועד ההכשרה",
                    },
                    "structure": {
                        "title": "הכשרת המשך",
                        "semiTitle": "4 ימי לימוד",
                        "content": {
                            "c1": "שיעור 1 - הכרת עולם התיירות וניתוח קהלים ויעדים",
                            "c2": "שיעור 2 - אסטרטגיה שיווקית ומכירה דרך שירות",
                            "c3": "שיעור 3 - רשתות חברתיות, קפמיינים והיבטים משפטיים",
                            "c4": "שיעור 4 - הכרת ורישום מערכת SKY 360 לסוכנים",
                        },
                        "bonus": {
                            "title": "בונוס",
                            "content1" : "חודש ראשון מתנה למערכת SKY 360",
                            "content2" : " לנרשמי ההכשרה תחת סוכנות הבית",

                        },
                    },
                    "duty": {
                        "title": "מה נדרש?",
                        "content": "השתתפות באופן סדיר בהרצאות ביצוע משימות והגשת עבודת גמר",
                    },
                    "promoteSection": {
                        "t1": "לעבוד בעולם התיירות!",
                        "t2": "בואו לעבוד בעולם התיירות עם התמיכה של הטובים ביותר!",
                    }
                },
                "forWho": {
                    "title": "למי מיועדת התכנית?",
                    "content": {
                        "c1":"למעוניינים ליצור הכנסה נוספת או ראשית בעולם התיירות",
                        "c2":"למעוניינים להשתלב כפרילנסרים תחת SKY 360",
                        "c3":"סוכני נסיעות המעוניינים לעבור למערכת המתקדמת ביותר למכירה  בעולם התיירות"
                    }
                },
                "finishSuccessfully":{
                    "title": "למסיימים את  ההכשרה בהצלחה",
                    "benefits":[
                        {
                            'title':'תינתן תעודת סוכן ומשווק תיירות מטעם חברת SKY360',
                            'img':"certification.png"
                        },
                        {
                            'title':'חודש ניסיון חינם במערכת  SKY360',
                            'img':"check_mark.png"
                        },
                        {
                            'title':'ליווי שוטף עם צוות המומחים בשנה הראשונה עד להצלחה',
                            'img':"check_mark.png"
                        },
                        // {
                        //     'title':'למתאימים ומעוניינים שילוב כשכירים עם תנאים מעולים בחברות המובילות במשק',
                        //     'img':"check_mark.png"
                        // },
                    ],
                },
                "payment": {
                    "title": "רישום חינם לוובינר",
                    "shah": "ש\"ח",
                    "content1": "הרישום לוובינר מסתיים בקרוב",
                    "content2": "מוזמנים להפיץ את הבשורה",
                    "registerNow": "הירשמו עכשיו!",
                    "form": {
                        "title": "הירשמו עכשיו!",
                        "full_name":"שם מלא",
                        "email":"אימייל",
                        "agency_name":"שם סוכנות",
                        "phone":"טלפון",
                        "birthday":"תאריך לידה",
                        "moreInfo":"האם קיים ניסיון בעולם התיירות : כן\\לא. פרט : ",
                        "action": "שלח רישום",
                        "actionWithPrice": "שלח ומעבר תשלום",
                        "cycleChoose" :"מעוניין/ת להצטרף למחזור",
                        "radioSelection" :"איך הייתם רוצים להתקדם?",
                        "independent" :"הכשרה מבית סקיי 360",
                        "employee" :"גישה למערכת ולהתחיל לעבוד!",
                        "onSuccess": "תודה על הרישום, הודעת וואטסאפ עם פרטים נוספים יישלחו בהקדם",
                        "onSuccessPay": "פרטיך נשלחו בהצלחה, הינך מועבר לתשלום",
                    },
                    "required":"יש להזין שדה חובה!"
                },
                "header": {
                    "title": "קורס סוכני ומשווקי תיירות",
                    'links': [
                        {
                            'text': "וובינר Agent 360",
                            'url': "webinar-header"
                        },
                        {
                            'text': "המומחים שלנו",
                            'url': "course-experts"
                        },
                        {
                            'text': "רישום לוובינר",
                            'url': "training-register-form"
                        },
                        {
                            'text': "הכשרת המשך",
                            'url': "course-info"
                        },
                    ],
                },
            },
            "courseLandingPage": {
                "title": "קורס סוכני ומשווקי תיירות",
                "paymentForm": {
                    "welcome": "שמחים שאתה מעוניין להתקדם לתשלום",
                    "title":"תשלום עבור קורס סוכני תיירות",
                    "errorMessageToRetry": "נסיון מס׳ {retryNum} - תשלום נכשל מול מחברת האשראי, יש להזין שוב את פרטי כרטיס אשראי או כרטיס אחר. אתר מקבל כרטיס VISA ו MASTERCARD בלבד.",
                    "cycle" :"מחזור",
                },
                "sections" :{
                    "section1": {
                        "title": "מטרות הקורס",
                        // "content1": "הכשרת משווקי תיירות כפרילנסים",
                        // "content2": "או אנשי מכירות בחברות התיירות הגדולות במשק",
                        "content1": "הכשרה מקצועית מהמובילים בענף התיירות",
                        "content2": "התחלת עבודה עם סיום הקורס",
                        "content3": "תמיכה וליווי מקצועי מבית SKY 360",
                    },
                    "section2": {
                        "title": "יעדי הקורס",
                        "content": {
                            "c1": "הקניית ידע בסיסי בעולם התיירות",
                            "c2":"זיהוי הזדמנויות ומגמות בעולם התיירות",
                            "c3":"יצירת בידול בשוק רווי תחרות",
                            "c4":"מתן כלים בעולמות: המכירה , שירות , אסטרטגיה שיווקית",
                            "c5":"הקניית ידע בסיסי בקידום ממומן ואורגני",
                            "c6":"לימוד מערכת SKY360 בשיתוף עם ALP",
                            "c7":"מערכת למכירת חבילות נופש  וטיסות של החברות הסיטונאיות הגדולות בישראל",
                        },
                    },
                    "section3": {
                        "title": "קורס משווקי תיירות",
                        "lesson": "שיעור",
                        "content": {
                            "c1": {
                               t: "הכרת עולם התיירות",
                               c:"שיעור 1",
                            },
                            "c2": {
                                t: "ניתוח קהלי יעד ויצירת נישות",
                                c:"שיעור 2",
                            },
                            "c3": {
                                t: "התאמת יעדי נופש לקהל יעד",
                                c:"שיעור 3",
                            },
                            "c4": {
                                t: "בידול , מקסום רווחים בשוק רווי תחרות",
                                c:"שיעור 4",
                            },
                            "c5": {
                                t: "עבודה מול קבוצות וארגונים",
                                c: "שיעור 5",
                            },
                             "c6": {
                                t: "יצירת נישה בעולם התיירות",
                                c:"שיעור 6",
                            },
                            "c7": {
                                t: "בניית אסטרטגיה שיווקית ושיתופי פעולה",
                                c:"שיעור 7",
                            },
                            "c8": {
                                t: "מכירה דרך שירות וטיפול בהתנגדויות",
                                c:"שיעור 8",
                            },
                            "c9": {
                                t: "שיווק בעולם התיירות",
                                c:"שיעור 9",
                            },
                            "c10": {
                                t: "היבטים משפטיים",
                                c:"שיעור 10",
                            },
                            "c11": {
                                t: "הדרכה על מערכת סוכני התיירות ותחילת עבודה",
                                c:"שיעור 11",
                            },
                            // "c11": {
                            //     t: "היבטים משפטיים ומיסוי לסוכן נסיעות",
                            //     c:"שיעור 11",
                            // },
                            // "c12": {
                            //     t: "הכרת מערכת ההזמנות של סוכני הנסיעות AMADEUS ALP",
                            //     c:"שיעור 12",
                            // },
                            // "c13-14": {
                            //     t: "הכרת מערכת SKY 360 למשווקי תיירות",
                            //     c:"שיעור 13-14",
                            // },
                            // "c15": {
                            //     t: "הכנה לעבודת גמר",
                            //     c:"שיעור 15",
                            // },
                            // "c16": {
                            //     t: "הצגת עבודות גמר",
                            //     c:"שיעור 16",
                            // },
                        },
                    }
                },
                "header": {
                    "title": "קורס סוכני ומשווקי תיירות",
                    'links': [
                        {
                            'text': "לעבוד בענף התיירות",
                            'url': ""
                        },
                        {
                            'text': "הקורס שלנו",
                            'url': "course-info"
                        },
                        {
                            'text': "המומחים שלנו",
                            'url': "course-experts"
                        },
                        // {
                        //     'text': "מערכת Agent360",
                        //     'url': "/"
                        // },
                        {
                            'text': "רישום לקורס",
                            'url': "course-register-form"
                        },
                    ],
                },
                "courseExperts":{
                    "title":"שיתופי פעולה שלנו במערכת Sky 360",
                    "titleSlider": "בהשתתפות מומחי ענף התיירות במשק",
                    "experts":[
                        {
                            "title":"חברת תעופה ישראלית וסיטונאית תיירות מהגדולות בענף",
                            "img":"israir-logo.png"
                        },
                        {
                            "title":"חברת תיירות סיטונאית המתמחה בטיולי נישה ומשפחות",
                            "img":"family_break.png"
                        },
                        // {
                        //     "title":"חברת תיירות סיטונאית מהמובילות בשוק הישראלי ",
                        //     "img":"mona_tours.png"
                        // },
                        {
                            "title":"חברת תעופה ישראלית וסיטונאית תיירות מהגדולות בענף",
                            "img":"arkia.png"
                        },
                        {
                            "title":"מערכת ההזמנות לרכישת טיסות וחבילות נופש של  סוכני הנסיעות בישראל",
                            "img":"alp.png"
                        },
                        {
                            "title":"החברה הגדולה בישראל לחבילות ספורט והופעות",
                            "img":"lord_tickets.png"
                        },
                        {
                            "title":"הבית לסוכני התיירות בישראל",
                            "img":"sky_360.png"
                        }
                    ]
                },
                "finishSuccessfully":{
                    "title": "למסיימים את הקורס",
                    "benefits":[
                        // {
                        //     'title':'"סטוריטלינג עסקי" <br> הרצאות מטעם <br> גל צחייק  <br>מייסד מכללת תוצאות',
                        //     'img':"check_mark.png"
                        // },
                        // {
                        //     'title':'3 חודשי ניסיון חינם במערכת הזמנות SKY360',
                        //     'img':"check_mark.png"
                        // },
                        {
                            'title':'ליווי שוטף עם צוות המומחים בשנה הראשונה עד להצלחה',
                            'img':"check_mark.png"
                        },
                        {
                            'title':'תינתן תעודת סוכן ומשווק תיירות מטעם חברת SKY360',
                            'img':"certification.png"
                        },
                        {
                            'title':'למתאימים ומעוניינים שילוב כשכירים עם תנאים מעולים בחברות המובילות במשק',
                            'img':"check_mark.png"
                        },
                    ],
                },
                "courseDetails": {
                    "location": {
                        "title": "איך לומדים?",
                        "content": "קורס אונליין",
                    },
                    "webinarLocation": {
                        "title": "מתי נפגשים?",
                        "content": "מפגש אונליין",
                    },
                    "structure": {
                        "title": "מתכונת הקורס",
                        "semiTitle": "כ 30 שעות אקדמאיות",
                        "content": {
                            "c1": "קורס מוקלט - מחולק ל11 שיעורים",
                            "c2": "כל שבוע יערך וובינר שאלות תשובות עם מנחי הקורס",
                            "c3": "כחלק מהשלמת הקורס המשתתפים יעבדו עם מערכת סוכני הנסיעות",
                        },
                        "bonus": {
                           "title": "קורס היברידי",
                           "content1" : "שילוב שיעורים מוקלטים עם מבחן מסכם",
                           "content2" : "במהלך הקורס נקיים Live שאלות תשובות והכוונה",
                        },
                    },
                    "duty": {
                        "title": "מה נדרש?",
                        "content": "השתתפות באופן סדיר בהרצאות ביצוע משימות והגשת עבודת גמר",
                    },
                    "promoteSection": {
                        "t1": "לעבוד בעולם התיירות!",
                        "t2": "בואו לעבוד בעולם התיירות עם התמיכה של הטובים ביותר!",
                    }
                },
                "forWho": {
                    "title": "למי מיועדת התכנית?",
                    "content": {
                        "c1":"למעוניינים ליצור הכנסה נוספת או ראשית בעולם התיירות",
                        "c2":"למעוניינים להשתלב כשכירים בחברות הגדולות במשק הישראלי",
                        "c3":"סוכני נסיעות המעוניינים לעבור השתלמות מכירה והתמקצעות בעולם התיירות"
                    }
                },
                "finish": {
                    "title": "למסיימים את הקורס בהצלחה - בונוס בשווי 5,000 שח",
                    "content": {
                        "c1": "תינתן תעודת \"משווק תיירות\" מבית סקיי גרופ",
                        "c2": "שיעור בונוס מעולם ה – NLP כיצד לשנות את המיינדסט שלכם",
                        "c3": "החשוב ביותר - אתר תיירות מכירתי שתוכלו מייד להתחיל לעבוד!"
                    },
                    "registerNow": "הירשמו עכשיו",
                },
                "payment": {
                    "title": "מחיר הקורס",
                    "shah": "ש\"ח",
                    "content1": "ניתן לחלק את התשלום עד 8 תשלומים",
                    "content2": "ללא ריבית באשראי או תשלום במזומן דרך העברה בנקאית",
                    "registerNow": "הירשמו עכשיו!",
                    "form": {
                        "title": "הירשמו עכשיו!",
                        "full_name":"שם מלא",
                        "email":"אימייל",
                        "phone":"טלפון",
                        "birthday":"תאריך לידה",
                        "moreInfo":"האם קיים ניסיון בעולם התיירות : כן\\לא. פרט : ",
                        "action": "שלח",
                        "cycleChoose" :"מעוניין/ת להצטרף למחזור",
                        "radioSelection" :"מעוניינים בסוף הקורס להשתלב בתור",
                        "independent" :"עצמאי",
                        "employee" :"שכיר",
                        "onSuccess": "פרטיך נשלחו בהצלחה, נציג יצור איתך קשר בהקדם",
                        // "onSuccess": "פרטיך נשלחו בהצלחה, הינך מועבר לתשלום",
                    },
                    "required":"יש להזין שדה חובה!"
                }
            },
            "private-flights":{
                "title": "טיסות פרטיות מנמל תעופה חיפה",
                "footer":{
                    'text':"כל הזכויות שמורות לsky360-. אין להשתמש בחומרי ותוכן האתר ללא אישור."
                },
                "destinations":{
                    'title':"טיסות פרטיות מנמל תעופה חיפה ליעדים",
                    'destinations':[{'text':"טורקיה", 'logo':"turkey.png"}, {'text':"איי יוון", logo:"greece.png"}, {'text':"קפריסין", logo:"cyprus.png"}]
                },
                "forWhom":{
                    'title':"למי השירות מתאים?",
                    'destinations':[{'text':"אנשי  עסקים", 'logo':"suitcase.png"}, {'text':"קבוצות כדורגל", logo:"football.png"}, {'text':"קבוצות כדורסל", logo:"basketball.png"}]
                },
                'aboutUs':{
                    'title':"על הפעילות שלנו",
                    'content':`טקסט פרטיות טקסט כאן על הפעילות שלנו בטיסות פרטיות טקסט כאן`
                },
                'contactUs':{
                    'title1':"לפרטים נוספים מלאו את הטופס",
                    'title2':"ואחד מנציגינו יחזור אליכם עם הצעת מחיר ופרטים נוספים בהרחבה.",
                    'form':{
                            'email':'דוא״ל',
                            'phone':'טלפון',
                            'fullName':'שם מלא',
                            'details':"פירוט הבקשה",
                            'button':"שלח",
                    }
                },
                "benefits":[
                        {
                            text:"תהליך בידוק מהיר",
                            logo:"quickCheck.png"
                        },
                        {
                            text:"הגעה שעה לפני הטיסה",
                            logo:"checkbox.png"
                        },
                        {
                            text:"עד 37 מקומות בטיסה",
                            logo:"sort.png"
                        }
                ]
            }            
        }
    }
};

export default translationsHe;