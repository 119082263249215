import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import configSlice from './config';

const reducer = combineReducers({
    config: configSlice,
});

export const store = configureStore({
    reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
