import * as React from 'react';
import dashboardImg from '../../assets/imgs/bo-dashboard-template.png';
import SkyLogoIcon from '../../icons/SkyLogoIcon';
import img from '../../assets/imgs/man-on-beach-working.png';

interface VerticalDividerProps {
  className?: string;
}

const VerticalDivider: React.FC<VerticalDividerProps> = ({
  className = '',
}) => {
  return <div className={`shrink-0 w-px bg-zinc-300 h-[100%] ${className}`} />;
};

interface RoundedBoxProps {
  text: string;
  className?: string;
}

const RoundedBox: React.FC<RoundedBoxProps> = ({ text, className = '' }) => {
  return (
    <div
      className={`justify-center items-end px-6 py-4 font-extrabold bg-slate-100 leading-[70%] rounded-[100px_0px_0px_100px] max-md:px-5 max-md:max-w-full ${className}`}
    >
      {text}
    </div>
  );
};

const businessModelPoints = [
  'שירות 24/7',
  'שיתופי פעולה עם השותפים העסקיים שלנו',
  'מערכות מתקדמות עם חיבור לכל ספקי התיירות',
  'ממשק מתקדם הכולל דוחות ומערכת ניהול לידים',
  'הקמת אתר תיירות בקלות ומהירות',
  'עמלות גבוהות',
];

const targetAudiencePoints = [
  'סוכן נסיעות עצמאי שאין לא ממשק אתר אינטרנט',
  'ורוצה לקפוץ מדרגה עם הטכנולוגיה שלנו',
  'אתם שאוהבים לטייל ורוצים לעשות מזה הרבה כסף',
  'כל מי שחולם על קריירה בתחום התיירות',
  'שכירים שמחפשים עבודה נוספת מהבית',
];

const BusinessModelComponent: React.FC = () => {
  return (
    <section className="self-center mt-16 max-md:mt-10 max-md:max-w-full">
      <h1 className="self-end font-extrabold text-black w-full md:hidden text-4xl leading-10">
        המודל העסקי
        <br />
        שלנו
      </h1>

      <div className="flex z-20 relative gap-5 max-md:flex-col max-md:gap-0  mb-[-100px]">
        <div className="flex flex-col  max-md:items-center ml-5 w-[30%] max-md:ml-0 max-md:w-full">
          <SkyLogoIcon
            fill={'#19263A'}
            className="self-stretch my-auto w-full aspect-[1.82] max-md:mt-10 max-md:max-w-full"
          />
        </div>
        <div className="flex flex-col max-md:items-center w-[70%] max-md:ml-0 max-md:w-full">
          <img
            loading="lazy"
            src={dashboardImg}
            alt={'dashboard image'}
            className={
              'grow w-full aspect-[2.56] max-md:mt-10 max-md:max-w-full'
            }
          />
        </div>
      </div>

      <div className="mt-0 w-full max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col ml-5 w-[70%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow px-5 mt-24 text-base text-right max-md:mt-10 max-md:max-w-full">
              <h1 className="self-end text-5xl font-extrabold text-black leading-[36px] w-full max-md:hidden">
                המודל העסקי
                <br />
                שלנו
              </h1>
              <div className="flex gap-5 items-start justify-start mt-14 text-slate-800 max-md:flex-wrap max-md:mt-24 max-md:max-w-full">
                <RoundedBox text="למה לעבוד איתנו?" />
                <VerticalDivider />

                <ul className="list-disc mr-5" dir="rtl">
                  {businessModelPoints.map((point, index) => (
                    <li key={index}>{point}</li>
                  ))}
                </ul>
              </div>
              <div className="flex gap-5 items-start justify-start mt-14 max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
                <RoundedBox
                  text="למי המערכת מיועדת?"
                  className="text-slate-800"
                />
                <VerticalDivider className="self-stretch" />

                <ul className="list-disc mr-5" dir="rtl">
                  {targetAudiencePoints.map((point, index) => (
                    <li key={index}>{point}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-[30%] max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src={img}
              alt="man-on-beach-working"
              className="w-full aspect-[0.64] max-md:mt-10 max-md:max-w-full"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BusinessModelComponent;
