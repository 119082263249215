import React, { memo, useEffect, useState } from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

import apiClient from '@skygroup/shared/API/apiClient';
import endpoints from '@skygroup/shared/API/endpoints';
import SpinLoader from '@skygroup/shared/components/spin-loader/SpinLoader';
import './lead-landing-page.less';

import TrainingInfo from "./widgets/training-info/TrainingInfo";
import Certificate from "./widgets/certificate/Certificate";
import WebinarRegisterForm from "./widgets/webinar-register-form/TrainingRegisterForm";
// @ts-ignore
import logo from "./../../assets/logo-sky.png";
import CourseExperts from './widgets/course-experts/CourseExperts';
import CourseFinishSuccessfully from './widgets/course-finish-successfully/CourseFinishSuccessfully';
import ExpertsSlider from './widgets/experts-slider/ExpertsSlider';
import WebinarHeader from './widgets/webinar-header/WebinarHeader';
import MainVideo from "./widgets/main-video/MainVideo";
import {useCourseCycles} from "./hooks/useWebinarCycles";
import CoursePaymentForm from "../course/widgets/course-payment-form/CoursePaymentForm";
import TrainingPaymentForm from "./widgets/training-payment-form/TrainingPaymentForm";

const LeadLandingPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [totalCount, setTotalCount] = useState(0);
    const [currentCourse, setCurrentCourse] = useState<any>();
    const {courseKey, aff} = useParams();
    const componentKey = 'course-landing-page';

    const leadSecret = searchParams.get("secret");

    const { data: cycles, isLoading: isLoadedCycles } = useCourseCycles(courseKey, aff);

    useEffect(() => {
        if (!isLoadedCycles) {
            if (cycles && courseKey && cycles[courseKey] && cycles[courseKey].status !== "close") {
                setCurrentCourse(cycles[courseKey]);
            } else {
                window.location.replace("https://www.sky360.co.il");
            }
        }
    }, [cycles, courseKey, isLoadedCycles]); // Dependencies array includes anything that, upon changing, should trigger this effect

    return (
        <div className={componentKey}>
            <WebinarHeader currentCourse={currentCourse} />
            { !leadSecret && <WebinarRegisterForm courseCycleId={courseKey} currentCourse={currentCourse}/> }
            { leadSecret && <TrainingPaymentForm secret={leadSecret} /> }
            <div className="footer">
                <img className="logo"  src={`/assets/sky_360.png`} alt="Sky 360"/>
            </div>
        </div>
    );
};

export default LeadLandingPage;