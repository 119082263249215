import React, {useEffect, useState} from 'react';
import './webinar-header.less';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';

const WebinarHeader = ({currentCourse}) => {
  const { header, title: titleT } = useSelector((data: RootState) => data.config.translations?.webinarLandingPage );
  const { currentLang } = useSelector((data: RootState) => data.config );
    const [ignore, setIgnore] = useState(false);
    const [isScrolledToTop, setIsScrolledToTop] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleScrollToTop = () => {
        setIsScrolledToTop(window.scrollY === 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScrollToTop);
        return () => {
            window.removeEventListener('scroll', handleScrollToTop);
        };
    }, []);

    const scrollFunction = (id: string) => {
        setIgnore(true);
        setTimeout(() => {
            const myDiv = document.getElementById(id);
            myDiv?.scrollIntoView({ behavior: 'smooth' });
            setTimeout(() => setIgnore(false), 2000);
        }, 100);
    };

    function handleScroll() {
        if (ignore) return;
        const section = searchParams.get("section") ?? "";
        const sectionDom = document.getElementById(section);

        if (sectionDom) {
            scrollFunction(section);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [ignore, location.pathname]);

    useEffect(() => {
       const timer = setTimeout(() => {
            handleScroll();
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

  return (
      <div id="webinar-header">
          <div className="links-wrapper content-layout">
              <div className="links">
                  {header.links.map((link, i) => {
                      return (
                          <Link
                              to={"?section=" + link.url}
                              key={i} className="link"
                              onClick={() => scrollFunction(link.url)}
                          >
                              {link.text}
                          </Link>
                      );
                  })}
              </div>
              <a href={"https://www.sky360.co.il"} target={"_blank"} rel="noreferrer"><img src={`/assets/sky_360.png`} className="logo"/></a>
          </div>
          { currentCourse ? <div className={"main-title"}>
              {titleT}
              <div className={'title-small'}>{currentCourse?.title}</div>
          </div> : null}
          {/*<div className={'back-training'}></div>*/}
      </div>
  );
};

export default WebinarHeader;
