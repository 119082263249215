import React from 'react';
import translationsHe from '../../translations/translationsHe';
import './privateFlights.less';
import { Button, Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import ContactUsPrivateFlights from './ContactUsPrivateFlights';
import PrivateFlightsProductList from './PrivateFlightsProductList';

const t = translationsHe.data.translation['private-flights'];

const PrivateFlights = () => {
  return (
    <div className="private-flights" id="private-flights">
      <div className="product-list destinations">
        <h2 className="title">{t.destinations.title}</h2>

        <PrivateFlightsProductList
          products={t.destinations.destinations}
        />
      </div>

      <div className="benefits">
        <img src={`/private-flights/airplane.png`} className="airplain" />
        <div className="benefits-wrapper">
          {t.benefits.map((benefit) => {
            return (
              <div key={benefit.text} className="benefit">
                <img src={`/private-flights/${benefit.logo}`} />
                {benefit.text}
              </div>
            );
          })}
        </div>
      </div>

      <div className="product-list for-whom">
        <h2 className="title">{t.forWhom.title}</h2>
        <PrivateFlightsProductList products={t.forWhom.destinations} />
      </div>

      <div className="about-us">
        <h2 className="title">{t.aboutUs.title}</h2>
        <p className="content">{t.aboutUs.content}</p>
      </div>

      <ContactUsPrivateFlights />

      <div className="footer">
        <img src={`/private-flights/footer.png`} className="footer-logo" />
        <p>{t.footer.text}</p>
      </div>
    </div>
  );
};

export default PrivateFlights;
