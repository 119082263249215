import * as React from 'react';
import CheckmarkIcon from '../../icons/CheckmarkIcon';
interface CardProps {
  title: string;
  description: string[];
  buttonText: string;
  registerUrl: string;
}

export const PlanCard: React.FC<CardProps> = ({
  title,
  description,
  buttonText,
  registerUrl,
}) => {
  return (
    <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow justify-center max-md:mt-10">
        <div className="bg-gradient-to-r from-[#00BDFF] to-[#4CD9ED] flex flex-col py-2 rounded-[50px] md:min-h-[450px]">
          <div className="flex flex-col ">
            <h2 className="p-3 self-center text-2xl font-extrabold text-right text-white">
              {title}
            </h2>
            <div className="flex flex-col items-center gap-3.5 px-10 max-lg:px-5">
              {description.map((text, index) => (
                <div
                  key={index + '_' + text}
                  className="flex items-start justify-start w-full"
                >
                  <CheckmarkIcon className="aspect-square min-w-[20px] ml-2" />
                  <p className="flex-auto text-base font-semibold leading-5 text-right text-slate-800 !mb-0">
                    {text}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <hr className="shrink-0 mt-auto bg-white border-2 border-white border-solid h-[3px] max-md:mt-10" />
          <a
            onClick={() => window.open(registerUrl, '_blank')}
            className="justify-center self-center px-2 py-2 mt-2 text-xl font-extrabold text-black bg-sky-300 border border-black border-solid rounded-[31.344px] max-md:px-5"
          >
            {buttonText}
          </a>
          {/* <a
            href="#"
            className="self-center mt-3.5 text-base !text-white underline"
          >
            לפרטים נוספים »
          </a> */}
        </div>
      </div>
    </div>
  );
};
