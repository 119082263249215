import React from 'react';
import { useLocation } from 'react-router-dom';
import translationsHe from '../../../translations/translationsHe';
import './product.less';
// @ts-ignore
import icon from './assets/AcceptIcon.png';
const product = translationsHe.data.translation.productPage.product;
const colors = translationsHe.data.translation.productPage.colors;

const Product = () => {
  const location = useLocation().pathname.substring(1);

  const selectedProduct = product.products[location];

  return (
    <div className="single-product content-wrapper" id="product">
      <div className="header">
        <img src={`/assets/${selectedProduct.img}`} className="image" />
        <img src={`/assets/${selectedProduct.logo}`} className="logo" />
      </div>
      <div>
        {
          <div className="list">
            {Object.keys(selectedProduct.texts).map((item, i) => {
              return (
                <div
                  className="list-item"
                  key={i}
                  style={{
                    backgroundColor: colors[i],
                  }}
                >
                  <img src={icon} className="icon" />
                  {selectedProduct.texts[item]}
                </div>
              );
            })}
          </div>
        }
      </div>
    </div>
  );
};

export default Product;
