import * as React from 'react';
import SkyLogoIcon from '../../icons/SkyLogoIcon';

interface MenuItemProps {
  title: string;
  items: any;
}

const MenuItem: React.FC<MenuItemProps> = ({ title, items }) => (
  <div className="px-0.5 pt-2.5 pl-5 pb-1">
    {title}
    <br />
    <br />
    {items.map((item, index) =>
      <React.Fragment key={index}>
        <a href={ item.href.startsWith("http") ? item.href :  `#${item.href}`} className={`font-light`}>
          {item.label}
        </a>
        <br />
      </React.Fragment>
    )}
  </div>
);

const FooterComponent: React.FC = () => {
  const menuItems: MenuItemProps[] = [
    {
      title: 'עמוד הבית',
      items: [
        { label: 'מי אנחנו', href: 'about-us' },
        { label: 'מוצרים', href: 'partners' },
        { label: 'שירות', href: 'services' },
        { label: 'טכנולוגיה', href: 'technology' },
        { label: 'צור קשר', className: 'grow', href: 'contact-us' },
      ]
    },
    {
      title: 'בין לקוחותינו',
      items: [
        { label: 'Familybreak - חבילות נופש', href: 'https://www.familybreak.co.il/' },
        { label: 'סוהו טראוול - קבוצות' , href: 'https://soho.sky360.co.il/' },
        { label: 'איסתא - מחלקת קבוצות', href: 'https://issta.sky360.co.il/' },
      ]
    },
    {
      title: 'כניסה למערכת',
      items: [
        { label: 'כניסה לסוכנים', href: 'https://bo.sky360.co.il/' },
        { label: 'רישום לסוכנים', href: 'https://bo.sky360.co.il/' },
      ]
    },
  ];

  return (
    <footer
      id="contact-us"
      className="flex justify-center items-center py-4 mt-24 w-full bg-slate-800 max-md:px-5 max-md:mt-10 max-md:max-w-full"
    >
      <div className="max-w-[1100px] w-[90%] mx-auto flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col ml-5 w-[71%] max-md:ml-0 max-md:w-full">
          <nav className="flex grow gap-5 justify-start px-0.5 text-base font-extrabold leading-7 text-right text-white max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
            {menuItems.map((item, index) => (
              <MenuItem key={index} title={item.title} items={item.items} />
            ))}
          </nav>
        </div>
        <div className="flex flex-col w-[29%] max-md:items-center max-md:ml-0 max-md:w-full">
          <SkyLogoIcon className="shrink-0 max-w-full aspect-[1.82] w-[308px] max-md:mt-10" />
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
