import * as React from 'react';

interface TextSectionProps {
  text: string;
  textColor?: string;
}

const TextSection: React.FC<TextSectionProps> = ({
  text,
  textColor = 'text-slate-800',
}) => {
  return (
    <div className="flex flex-col w-[75%] max-md:ml-0 max-md:w-full">
      {text && (
        <p
          className={`text-xl leading-6 text-right ${textColor} mb-[0px] max-md:mt-5 max-md:max-w-full`}
          dangerouslySetInnerHTML={{
            __html: text.replace(/(?:\r\n|\r|\n)/g, '<br>'),
          }}
        />
      )}
    </div>
  );
};

interface ButtonSectionProps {
  text: string;
  buttonColor?: string;
}

const ButtonSection: React.FC<ButtonSectionProps> = ({
  text,
  buttonColor = 'bg-[#273B42]',
}) => {
  return (
    <div className="flex flex-col ml-5 w-[25%] max-md:ml-0 max-md:w-full">
      <button
        disabled
        className={`justify-center items-end max-sm:text-center self-stretch p-3 my-auto w-full text-2xl font-extrabold text-center text-white rounded-2xl ${buttonColor} max-md:px-5 `}
      >
        {text}
      </button>
    </div>
  );
};

interface Props {
  textColor?: string;
  textSectionContent: string;
  buttonSectionContent: string;
  backgroundColor?: string;
  buttonColor?: string;
}

function WebsiteInfoFullRowComponent({
  textColor,
  textSectionContent = '',
  buttonSectionContent = '',
  backgroundColor = 'bg-gradient-to-r from-[#6ECDEC] to-[#4CD9ED]',
  buttonColor,
}: Props) {
  return (
    <div
      className={`${backgroundColor} rounded-[10px] flex justify-center items-center px-8 py-7 my-5 w-full max-md:px-5 max-md:max-w-full`}
    >
      <div className="w-full max-w-[100%] max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <ButtonSection
            text={buttonSectionContent}
            buttonColor={buttonColor}
          />
          <TextSection text={textSectionContent} textColor={textColor} />
        </div>
      </div>
    </div>
  );
}

export default WebsiteInfoFullRowComponent;
