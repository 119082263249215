import * as React from 'react';
import { PlanCard } from './PlanCard';
interface CardProps {
  title: string;
  description: string[];
  buttonText: string;
}

const SubscriptionPlansComponent = ({
  registerUrl,
}: {
  registerUrl: string;
}) => {
  const cardData: CardProps[] = [
    {
      title: 'SKY 360',
      description: [
        'מערכת לסוכן תיירות הכוללת טיסות וחבילות נופש,טיסות סדירות וממשק בתי מלון(B2B)',
        'אתר מכירות למשווק (B2C) הכולל מיתוג.',
        'ניהול הזמנות ויצירת לידים',
        'סליקה עצמאית של הסוכנות',
        'הקמת חבילות משולבות טיסה מלון ואטרקציות',
        'דוחות לסוכן ולספקים',
      ],
      buttonText: 'לרישום חינם',
    },
    {
      title: 'AGENT 360 PRO',
      description: [
        'מערכת לסוכן תיירות הכוללת טיסות וחבילות נופש,טיסות סדירות וממשק בתי מלון(B2B+B2C)',
        'אתר מכירות ממותג למשווק (B2C) הכולל יצירת ייחודיות .',
        'ניהול הזמנות ויצירת לידים',
        'דוחות עמלה לסוכן',
        'סליקת לקוח עם פיצול אשראי',
      ],
      buttonText: 'לרישום חינם',
    },
    {
      title: 'AGENT 360',
      description: [
        'אתר ומערכת לסוכן תיירות הכוללת טיסות וחבילות נופש,טיסות סדירות וממשק בתי מלון (B2B+B2C)',
        'ניהול הזמנות ויצירת לידים',
        'דוחות עמלה לסוכן',
        'סליקת לקוח עם פיצול אשראי',
      ],
      buttonText: 'לרישום חינם',
    },
  ];

  return (
    <section
      id={'plans'}
      className="z-10 self-center px-5 max-md:max-w-full mt-[-150px]"
    >
      <div className="flex items-start gap-5 max-md:flex-col max-md:gap-0">
        {cardData.map((card, index) => (
          <PlanCard key={index} {...card} registerUrl={registerUrl} />
        ))}
      </div>
    </section>
  );
};

export default SubscriptionPlansComponent;
