import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import './course-experts.less';

const CourseExperts = () => {
  const { courseExperts } = useSelector(
    (data: RootState) => data.config.translations?.courseLandingPage
  );

  return (
    <div id="course-experts">
      <h3 className="title">{courseExperts.title}</h3>

      <div className="experts-wrapper content-layout">
        {courseExperts.experts.map((expert, i) => {
          return (
            <div key={i} className="expert">
              <div className="img">
                <img src={`/assets/${expert.img}`} />
              </div>
              {expert.title}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CourseExperts;
