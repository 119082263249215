import React from 'react';

const CheckmarkIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_158_113)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 0C6.72656 0 0 6.72656 0 15C0 23.2734 6.72656 30 15 30C23.2734 30 30 23.2734 30 15C30 6.72656 23.2734 0 15 0Z"
          fill="#273B42"
        />
        <path
          d="M12.1113 21.8613C10.1953 19.9453 8.29102 18.0117 6.36914 16.0957C6.1582 15.8848 6.1582 15.5332 6.36914 15.3223L8.57812 13.1133C8.78906 12.9023 9.14063 12.9023 9.35156 13.1133L12.5098 16.2715L20.6309 8.14453C20.8477 7.93359 21.1934 7.93359 21.4102 8.14453L23.625 10.3594C23.8418 10.5762 23.8418 10.9219 23.625 11.1328L12.8848 21.8613C12.6738 22.0781 12.3281 22.0781 12.1113 21.8613Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_158_113">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckmarkIcon;
