import React from 'react';
// @ts-ignore
import logo from './assets/Icon360ContactUs.png';
import './contactUs.less';
import translationsHe from '../../translations/translationsHe';
import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';

const contactUs = translationsHe.data.translation.productPage.contactUs;

const ContactUs = () => {
  const [form] = useForm();
  return (
    <div className="contact-us content-wrapper" id="contact-us">
      <img src={logo} />
      <div className="title">{contactUs.title}</div>
      <div className="sub-title">{contactUs.subTitle}</div>

      <div>
        <Form className="form" form={form}>
          <div className="info">
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: contactUs.fieldRequired,
                },
              ]}
            >
              <Input placeholder={contactUs.phone} className="input" />
            </Form.Item>
            <Form.Item
              name="fullName"
              rules={[
                {
                  required: true,
                  message: contactUs.fieldRequired,
                },
              ]}
            >
              <Input
                type="text"
                placeholder={contactUs.fullName}
                className="input"
              />
            </Form.Item>
          </div>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: contactUs.fieldRequired,
              },
            ]}
          >
            <Input
              type="text"
              placeholder={contactUs.email}
              className="input"
            />
          </Form.Item>
          <Form.Item
            name="textArea"
            rules={[
              {
                required: true,
                message: contactUs.fieldRequired,
              },
            ]}
          >
            <Input.TextArea
              placeholder={contactUs.textArea}
              className="input textarea"
            />
          </Form.Item>
          <Button className="btn" onClick={() => form.validateFields()}>
            {contactUs.button}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default ContactUs;
