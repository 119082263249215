import * as React from 'react';
import { Form, Row, Input, Col, InputNumber } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import skyLogo from '../../assets/imgs/sky-logo.png';
import img from '../../assets/imgs/man-on-beach-working.png';
import apiClient from '@skygroup/shared/API/apiClient';
import SpinLoader from '@skygroup/shared/components/spin-loader/SpinLoader';
const SKY_AGENT_LEAD_TYPE_ID = '2';
const SKY_AGENT_LEAD_API = 'b2c/lead/create';

const ContactForm: React.FC = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const [formMessage, setFormMessage] = React.useState('');

  const submitForm = async (data) => {
    try {
      const formData = new FormData();
      formData.append('lead_type_id', SKY_AGENT_LEAD_TYPE_ID);

      for (const key in data) {
        formData.append(key, data[key] ? data[key] : '');
      }

      setIsLoading(true);
      apiClient
        .post(SKY_AGENT_LEAD_API, formData)
        .then((res) => {
          if (res.data.status === 'success') {
            setFormMessage('פרטיך נקלטו בהצלחה, נציג ייצור עמך קשר בהקדם.');
            form.resetFields();
          } else {
            setFormMessage(res.data.status);
          }
        })
        .catch((err) => {
          setFormMessage(JSON.stringify(err));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (err) {
      setFormMessage(JSON.stringify(err));
    }
  };

  return (
    <SpinLoader isLoading={isLoading}>
      <Form
        className="flex z-10 flex-col mb-0 w-[300px] max-md:w-[70%] max-md:mb-2.5"
        layout="vertical"
        onFinish={submitForm}
        form={form}
      >
        <Row>
          <Col span={24}>
            <Form.Item name={'full_name'}>
              <Input
                placeholder="שם מלא"
                className={
                  'justify-center min-h-[60px] items-end !px-4 !py-2 text-right bg-white !rounded-md border-2 !border-sky-300 border-solid max-md:px-5 max-md:max-w-full placeholder:!text-gray-800 placeholder:!text-base placeholder:!font-extrabold placeholder:!leading-2 !text-gray-800 !text-base !font-extrabold !leading-2'
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name={'phone'}>
              <InputNumber
                type={'number'}
                placeholder="טלפון"
                className="justify-center min-h-[60px]  items-end  text-right bg-white !rounded-md border-2 !border-sky-300 border-solid max-md:px-5 !w-full  max-md:max-w-full placeholder:!text-gray-800 placeholder:!text-base placeholder:!font-extrabold placeholder:!leading-2 !text-gray-800 !text-base !font-extrabold !leading-2"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name={'email'}
              rules={[{ type: 'email', message: 'כתובת דוא"ל לא תקינה' }]}
            >
              <Input
                placeholder={'דוא״ל'}
                className="justify-center min-h-[60px]  items-end !px-4 !py-2 text-right bg-white !rounded-md border-2 !border-sky-300 border-solid max-md:px-5 max-md:max-w-full placeholder:!text-gray-800 placeholder:!text-base placeholder:!font-extrabold placeholder:!leading-2 !text-gray-800 !text-base !font-extrabold !leading-2"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item name={'comment'}>
              <TextArea
                placeholder={'השאר הודעה'}
                className="items-end min-h-[60px]  !px-4 !py-2 text-right bg-white !rounded-md border-2 !border-sky-300 border-solid max-md:px-5 max-md:pb-10 max-md:max-w-full placeholder:!text-gray-800 placeholder:!text-base placeholder:!font-extrabold placeholder:!leading-2 !text-gray-800 !text-base !font-extrabold !leading-2"
                rows={4}
              />
            </Form.Item>
          </Col>
        </Row>

        {formMessage ? (
          <Row>
            <Col span={24}>
              <div className="successMessage justify-center items-center text-center !text-sky-300 !text-xl !font-extrabold !leading-2">
                {formMessage}
              </div>
            </Col>
          </Row>
        ) : null}

        <button
          onClick={() => submitForm(form.getFieldsValue())}
          className="w-[30%] min-w-[160px] self-center justify-center items-center px-4 py-2 mt-2 mb-[-60px] max-w-full text-xl text-black whitespace-nowrap bg-sky-300 !rounded-md border border-black border-solid max-md:px-5"
        >
          שלח
        </button>
      </Form>
    </SpinLoader>
  );
};

function RegisterFormComponent() {
  return (
    <section className="mt-5 max-w-[800px] !overflow-visible">
      <h1 className="mt-24 text-5xl font-extrabold text-center leading-[27px] text-slate-800 max-md:mt-10 max-md:text-4xl">
        צור קשר
      </h1>
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <aside className="flex flex-col ml-5 w-[30%] ml-[-100px] z-[21] relative max-md:ml-0 max-md:w-full">
          <img
            loading="lazy"
            src={img}
            alt="man-on-beach-working"
            className="mt-6 w-full aspect-[0.75] max-md:hidden"
          />
        </aside>
        <div className="flex flex-col w-[70%] max-md:ml-0 max-md:w-full">
          <header className="flex flex-row-reverse grow text-xl font-extrabold leading-5 text-slate-800 max-md:max-w-full">
            <img
              loading="lazy"
              src={skyLogo}
              alt="Company logo"
              className="max-w-full h-[72px] w-[250px] max-md:h-[60px] max-md:w-[206px]"
            />
          </header>
          <div className="flex flex-col items-center px-4 py-10 bg-slate-100 rounded-[70px] max-md:rounded-[30px]  max-md:px-5 max-md:max-w-full">
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  );
}

export default RegisterFormComponent;
