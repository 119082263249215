import axios from 'axios';
import {message} from "antd";
import endpoints from "./endpoints";
import {toInteger} from "lodash";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
});

if (process.env.REACT_APP_TYPE === "bo") {
    const token = localStorage.getItem('token');

    apiClient.defaults.withCredentials = true;
    apiClient.interceptors.request.use(config => {
      config.withCredentials = true;
      // @ts-ignore
      config.headers.common['x-tid'] = window["tenant_id"];
      // @ts-ignore
      config.headers.common['Authorization'] =  "Bearer " + token;
      if (config.url && config.url.startsWith('/ms')) {
        config.withCredentials = false;
        config.baseURL = process.env.REACT_APP_MS_BASE_URL;
        config.url = config.url.replace("/ms", "");
      }

      return config;
    });

    let isRefreshing = false;
    let refreshSubscribers: any[] = [];
    const subscribeTokenRefresh = (cb: any) =>
        refreshSubscribers.push(cb);

    const onRefreshed = (token: string) => {
        console.log("refreshing ", refreshSubscribers.length, " subscribers");
        refreshSubscribers.map(cb => cb(token));
        refreshSubscribers = [];
    };

    apiClient.interceptors.response.use(undefined,
        error => {
            const status = error.response ? error.response.status : false;
            const originalRequest = error.config;

            if (error.config.url ===  endpoints.AUTH.REFRESH_TOKEN) {
                console.log('REDIRECT TO LOGIN');
                isRefreshing = false;
                window.location.href = "/login";

                // store.dispatch("logout").then(() => {
                //     isRefreshing = false;
                // });
            }

            // if (status === 401) {
            //     if (!isRefreshing) {
            //         isRefreshing = true;
            //         console.log('dispatching refresh');
            //         apiClient.get(endpoints.AUTH.REFRESH_TOKEN).then( res => {
            //             debugger
            //             if (res.data.token) {
            //                 onRefreshed(res.data.token);
            //             } else {
            //                 throw error;
            //             }
            //         }).catch(() => {
            //             debugger
            //             isRefreshing = false;
            //             throw error;
            //         });
            //     }
            //
            //     return new Promise(resolve => {
            //         subscribeTokenRefresh(token => {
            //             debugger
            //             // replace the expired token and retry
            //             originalRequest.headers["Authorization"] = "Bearer " + token;
            //             resolve(axios(originalRequest));
            //         });
            //     });
            // }
            if (error.config.url !== endpoints.AUTH.USER_INFO) {
                message.error(error.response.status + " - " + error.response.statusText);
            }
            console.error(error);
            // throw error;
            return Promise.reject(error);
        }
    );

} else {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('t') || localStorage.getItem('agent-admin-token');

    apiClient.interceptors.request.use(function(config) {
        if (token) {
            // @ts-ignore
            config.headers.common['Authorization'] =  "Bearer " + token;
        }

        // Here, you can set the baseURL based on a function or condition
        if (config.url && config.url.startsWith('/ms')) {

            const MS_HOST_OVV = localStorage.getItem("MS_HOST");

            config.baseURL =  MS_HOST_OVV ? MS_HOST_OVV :  process.env.REACT_APP_MS_BASE_URL;
            config.url = config.url.replace("/ms", "");
        }
        return config;
    }, function(error) {
        return Promise.reject(error);
    });
    apiClient.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            message.error(error.response.status + " - " + error.response.statusText);
            console.error(error);
            throw error;
        }
    );
}

if (process.env.REACT_APP_TENANT_ID && toInteger(process.env.REACT_APP_TENANT_ID) > 0) {
    apiClient.interceptors.request.use(config => {
        if (!config?.url?.includes("tid")) {
            config.url = config?.url?.includes("?") ?
                config.url + "&tid=" + process.env.REACT_APP_TENANT_ID :
                config.url + "?tid=" + process.env.REACT_APP_TENANT_ID;
        }

        // If params object doesn't exist, initialize it
        if (!config.params) {
            config.params = {};
        }

        // Add local parameter
        config.params['tenantId'] = process.env.REACT_APP_TENANT_ID;
        config.params['wbId'] = process.env.REACT_APP_WEBSITE_ID;

        return config;
    });
}
export default apiClient;
