import { Button, Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import translationsHe from '../../translations/translationsHe';

const ContactUsPrivateFlights = () => {
  const privateFlights = translationsHe.data.translation['private-flights'];

  return (
    <div className="contact-us-private-flights">
      <div className="header-1">{privateFlights.contactUs.title1}</div>
      <div className="header-2">{privateFlights.contactUs.title2}</div>

      <Form className="form">
        <div>
          <Form.Item>
            <Input placeholder={privateFlights.contactUs.form.fullName} />
          </Form.Item>
          <Form.Item>
            <Input placeholder={privateFlights.contactUs.form.phone} />
          </Form.Item>
          <Form.Item>
            <Input placeholder={privateFlights.contactUs.form.email} />
          </Form.Item>
        </div>
        <div className="textarea">
          <Form.Item>
            <TextArea placeholder={privateFlights.contactUs.form.details} />
          </Form.Item>
          <Button>{privateFlights.contactUs.form.button}</Button>
        </div>
      </Form>
    </div>
  );
};

export default ContactUsPrivateFlights;
