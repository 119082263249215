import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import './course-experts.less';

const CourseExperts = () => {
    const { courseExperts, courseDetails: tranDetails } = useSelector(
        (data: RootState) => data.config.translations?.courseLandingPage
    );

    return (
        <>
            <div id={"promote-cnt"}>
                <div className={"title"}>בואו להכיר את Agent 360 - מה מחכה לכם בוובינר</div>
                <div className={"title2"}>
                    <ul>
                        <li>✔️ תפקידו של סוכן התיירות</li>
                        <li>✔️ תיירות במציאות משתנה - ממשבר להזדמנות</li>
                        <li>✔️ פוטנציאל הרווח - עם המערכת Sky 360</li>
                        <li>✔️ הצגת מערכת ההזמנות לסוכני תיירות מבית Sky360</li>
                        <li>✔️ תקציר הודות הכשרת סוכני תיירות על ידי המומחים בענף</li>
                        <li>✔️ פאנל מומחים</li>
                        <li>✔️ שאלות ותשובות</li>
                    </ul>

                </div>
            </div>

            <div id="course-experts">
                <h3 className="title">{courseExperts.title}</h3>

                <div className="experts-wrapper content-layout">
                    {courseExperts.experts.map((expert, i) => {
                        return (
                            <div key={i} className="expert">
                                <div className="img">
                                    <img src={`/assets/${expert.img}`}/>
                                </div>
                                {expert.title}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default CourseExperts;
