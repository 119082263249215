import React from 'react';
import { useLocation } from 'react-router-dom';
import ContactUs from '../../../components/ContactUs/ContactUs';
import Footer from '../../../components/Footer/Footer';
import ProductHeader from '../../../components/ProductHeader/ProductHeader';
import Product from '../product/Product';

const SingleProduct = () => {
  const location = useLocation();

  return (
    <div>
      <Product />
      <ContactUs />
      <Footer />
    </div>
  );
};
export default SingleProduct;
