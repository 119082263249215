import React, { memo } from 'react';
import {Col, Layout, Row} from "antd";
import "./course-info.less";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";

const CourseInfo = () => {
    const { sections:tranSections,courseDetails: tranDetails, forWho:tanForWho } = useSelector((data:RootState) => data.config.translations?.courseLandingPage);

    const columnSizeBoxNum = {
        xs: 24,
        md: 6,
    };
    const columnSizeDesc = {
        xs: 24,
        md: 10,
    };
    return (
        <div id="course-info">
            <div className="content-layout">
                <div className="course-section course-section-1">
                    <Row>
                        <Col {...columnSizeBoxNum}>
                            <div className="box-number">
                                <i>01</i>
                                <div>{tranSections.section1.title}</div>
                            </div>
                        </Col>
                        <Col {...columnSizeDesc}>
                            <h2>{tranSections.section1.title}</h2>
                            <ul>
                            <li className='c1'>{tranSections.section1.content1}</li>
                            <li className='c2'>{tranSections.section1.content2}</li>
                            <li className='c2'>{tranSections.section1.content3}</li>
                            </ul>
                        </Col>
                    </Row>
                </div>

                <div className="course-section course-section-2">
                    <Row>
                        <Col {...columnSizeBoxNum}>
                            <div className="box-number">
                                <i>02</i>
                                <div>{tranSections.section2.title}</div>
                            </div>
                        </Col>
                        <Col {...columnSizeDesc}>
                            <h2>{tranSections.section2.title}</h2>
                            <ul>
                                {Object.keys(tranSections.section2.content).map((key, value) => {
                                    return (<li key={key}>{tranSections.section2.content[key]}</li>);
                                })}
                            </ul>
                        </Col>
                    </Row>
                </div>
                
                <div className="course-section course-section-3">
                    <Row>
                        <Col {...columnSizeBoxNum}>
                            <div className="box-number">
                                <i>03</i>
                                <div>{tranSections.section3.title}</div>
                            </div>
                        </Col>
                        <Col {...columnSizeDesc}>
                            <h2>{tranSections.section3.title}</h2>
                            <ul>
                                {Object.keys(tranSections.section3.content).map((key, index) => {
                                    const lesson = tranSections.section3.content[key];
                                    return (<li key={index}><b>{lesson.c}:</b> {lesson.t}</li>);
                                })}
                            </ul>
                        </Col>
                    </Row>
                </div>

                <Row id="course-details">
                    <Col id="course-where" xs={24} md={6}>
                        <div className="detail-box">
                            <div className="icon"></div>
                            <div className="title">{tranDetails.location.title}</div>
                            <div className="content">{tranDetails.location.content}</div>
                        </div>
                    </Col>
                    <Col id="course-structure" xs={24} md={12}>
                        <div className="detail-box">
                            <div className="icon"></div>
                            <div className="title">{tranDetails.structure.title}</div>
                            <div className="semi-title">{tranDetails.structure.semiTitle}</div>
                            <div className="content">
                                <ul>
                                    {Object.keys(tranDetails.structure.content).map((key, index) => {
                                        return (<li key={index}>{tranDetails.structure.content[key]}</li>);
                                    })}
                                </ul>
                                <div className={"bonus"}>
                                    <div className={"title"}>{tranDetails.structure.bonus.title}</div>
                                    <div className={"content"}>{tranDetails.structure.bonus.content1}</div>
                                    <div className={"content"}>{tranDetails.structure.bonus.content2}</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col id="course-duty" xs={24} md={6}>
                        <div className="detail-box">
                            <div className="icon"></div>
                            <div className="title">{tanForWho.title}</div>
                            <ul>
                                {Object.keys(tanForWho.content).map((key, index) => {
                                    return (<li key={index}>{tanForWho.content[key]}</li>);
                                })}
                            </ul>
                        </div>
                    </Col>
                    {/*<Col id="course-duty" xs={24} md={6}>*/}
                    {/*    <div className="detail-box">*/}
                    {/*        <div className="icon"></div>*/}
                    {/*        <div className="title">{tranDetails.duty.title}</div>*/}
                    {/*        <div className="content">{tranDetails.duty.content}</div>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                </Row>

                {/*<Row id="course-who">*/}
                {/*    <Col md={12} xs={24}>*/}
                {/*        <div className="title">{tanForWho.title}</div>*/}
                {/*    </Col>*/}
                {/*    <Col md={12}>*/}
                {/*        <div className="content">*/}
                {/*            <ul>*/}
                {/*            {Object.keys(tanForWho.content).map((key, index) => {*/}
                {/*                return (<li key={index}>{tanForWho.content[key]}</li>);*/}
                {/*            })}*/}
                {/*            </ul>*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

                <div id={"promote-cnt"}>
                    <div className={"title"}>{tranDetails.promoteSection.t1}</div>
                    <div className={"title2"}>{tranDetails.promoteSection.t2}</div>
                </div>
            </div>
        </div>
    );
}
export default memo(CourseInfo);
