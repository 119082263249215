import React, {memo, useCallback, useEffect, useState} from 'react';
import {Button, Col, DatePicker, Form, Input, Layout, message, Radio, Row, Select, Space} from "antd";
import "./training-register-form.less";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {AiOutlineArrowDown} from "react-icons/ai";
import locale from 'antd/es/date-picker/locale/he_IL';
import moment from "moment";
import ButtonWithArrow from "../../../../components/Common/ButtonWithArrow/ButtonWithArrow";
import TextArea from "antd/es/input/TextArea";
import SpinLoader from '@skygroup/shared/components/spin-loader/SpinLoader';
import RadioGroupContext from "antd/es/radio/context";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {debug} from "node:util";
import apiClient from '@skygroup/shared/API/apiClient';

const TrainingRegisterForm = ({ courseCycleId, currentCourse}) => {
    const COURSE_LEAD_TYPE_ID = "1";
    const COURSE_LEAD_API = "b2c/lead/create";

    const {payment: translation} = useSelector((data: RootState) => data.config.translations?.webinarLandingPage);
    const [isLoading, setIsLoading] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    const [formMessage, setFormMessage] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    let { aff } = useParams();
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const columnSizeBoxNum = {
        xs: 24,
        md: 6,
    };
    const requiredRule = {required: true, message: translation.required};

    useEffect(() => {

    }, [isLoading, showPayment, formMessage]);

    const createLead = (leadForm) => {
        const formData = new FormData();
        formData.append("lead_type_id", COURSE_LEAD_TYPE_ID);
        formData.append("course_cycle", courseCycleId);
        formData.append("affiliate", aff ?? "");

        for (const key in leadForm) {
            formData.append(key, leadForm[key] ? leadForm[key] : '');
        }

        setIsLoading(true);
        apiClient
            .post(COURSE_LEAD_API, formData)
            .then((res) => {
                if (res.data.status == "success") {
                    form.resetFields();
                    if (currentCourse.price > 0) {
                    setFormMessage(translation.form.onSuccessPay);
                        // form.resetFields();
                        setTimeout(() => {

                            window.location.href = location.pathname + "?secret=" + res.data.data.secret;
                        }, 3000);
                    } else {
                        setFormMessage(translation.form.onSuccess);
                    }
                }
            })
            .catch(err => {

            })
            .finally(() => {
                setIsLoading(false);
            });

    };

    return (
        <div id="training-register-form">
            <div className="content-layout">
                <Row>
                    <Col xs={24}>
                        <div className="title">{translation.title}</div>
                        <div className="more-info">
                            <div>{translation.content1}</div>
                            <div>{translation.content2}</div>
                        </div>
                        <div className="reg-now-title">
                            {translation.registerNow}
                        </div>
                        { currentCourse?.extra_info?.length > 0 ?
                            <div className={"extra-info"}>
                                { currentCourse?.extra_info.map( ((a,i) => <div key={i}>{a}</div>) ) }
                            </div> :
                            ""
                        }
                        <AiOutlineArrowDown size={60} style={{margin:'0 auto 30px auto'}}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={20} md={14} style={{margin:'auto'}}>
                        {!showPayment ?
                        <SpinLoader isLoading={isLoading}>
                            <Form
                                form={form}
                                layout="inline"
                                onFinish={createLead}
                            >
                                <Form.Item name="full_name" rules={[requiredRule]}>
                                    <Input placeholder={"* " + translation.form.full_name}/>
                                </Form.Item>
                                <Form.Item name="email" rules={[requiredRule,
                                    {
                                        type: 'email',
                                        message: 'אימייל לא תקין',
                                    }]}>
                                    <Input placeholder={"* " + translation.form.email}/>
                                </Form.Item>
                                <Form.Item name="phone" rules={[requiredRule]}>
                                    <Input placeholder={"* " + translation.form.phone}/>
                                </Form.Item>
                                <Form.Item name="birthdate">
                                    <DatePicker
                                        locale={locale}
                                        format="MM/DD/YYYY"
                                        placeholder={translation.form.birthday}
                                        disabledDate= { (current) => {
                                            // Disable all dates that are later than today
                                            return current && current > moment().endOf('day');
                                        }}
                                    />
                                </Form.Item>
                                {/*<Form.Item className={"course-cycles"} name="course_cycle" >*/}
                                {/*    <span>{translation.form.cycleChoose}</span>*/}
                                {/*    <Select onChange={(val) => { form.setFieldValue('course_cycle',val);}}>*/}
                                {/*        {Object.entries(cycles).map(([key, value], index) => (*/}
                                {/*            <Select.Option value={key} key={index}>*/}
                                {/*                {value as string}*/}
                                {/*            </Select.Option>*/}
                                {/*        ))}*/}
                                {/*    </Select>*/}
                                {/*</Form.Item>*/}
                                <Form.Item className={"interested-in"} name="interested" >
                                    <span>{translation.form.radioSelection}</span>
                                    <Radio.Group onChange={ (e) => { form.setFieldValue("interested", e.target.value);}}>
                                        <Radio value={"independent"}>{translation.form.independent}</Radio>
                                        <Radio value={"employee"}>{translation.form.employee}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <div className={'title title-med'}> {currentCourse?.title}</div>
                                <ButtonWithArrow text={translation.form.action} color="#00264b" action={form.submit}/>
                                {formMessage ? <div className="successMessage">{formMessage}</div> : null}
                            </Form>
                        </SpinLoader> :
                        <div>Payment Form</div>
                        }
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default memo(TrainingRegisterForm);
