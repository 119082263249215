import React from 'react';

import './button-with-arrow.less';
import {AiOutlineArrowLeft, AiOutlineArrowRight} from "react-icons/ai";

const ButtonWithArrow = (Props) => {
  return (
    <div className="button-with-arrow">
        <AiOutlineArrowLeft color={Props.color} size="40px" />
        <div onClick={Props?.action} className="btn ease" style={{background:Props.color}} >{Props.text}</div>
        <AiOutlineArrowRight color={Props.color} size="40px" />
    </div>
  );
};

export default ButtonWithArrow;
