import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import SpinLoader from '@skygroup/shared/components/spin-loader/SpinLoader';
import {HiBadgeCheck} from "react-icons/hi";
import './paymentCompletion.less';
import {loadTransaction} from "./trainingPaymentApi";
import {RootState} from "../../../../store/store";
const OrderStatuses = {
    'PAYMENT_FINISH': "payment_finish",
    'PAYMENT_FAILED': "payment_failed",
};
const PaymentCompletion = ({ customerSecret, transactionId }) => {
  //const { completionForm:t } = useSelector((state: RootState) => state.config.translations);

  const [isLoading, setIsLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState('');

    const [formApiError, setApiError] = useState(null);
    const [formApiSuccess, setApiSuccess] = useState<any>(null);

    useEffect( () => {
        loadTransactionInfo();
    }, []);

    // load transaction message
    const loadTransactionInfo = async () => {
        try {
            const formData =  {
                'secret': customerSecret ,
                'transaction_id': transactionId,
            };
            // this is update action
            const res = await loadTransaction(formData, setIsLoading);
            if (res.data.status === "success") {
                setApiError(null);
                setOrderStatus(res.data.data.status);
            } else {
                // error return from server
                setApiError(res.data.data);
            }
        } catch (error: any) {
            setApiError(error);
        }
    };

    const getTitle = () => {
        switch (orderStatus) {
            case OrderStatuses.PAYMENT_FINISH:
                return "תשלום עבר בהצלחה";
                // return t.paymentFinish.title;
            case OrderStatuses.PAYMENT_FAILED:
                return "תשלום נכשל";
                // return t.paymentFail.title;
        }
    }

    const getInfo = () => {
        let baseLines =
        <>
            {/*<div className="name">{customerInfo.customer_first_name} {customerInfo.customer_last_name}, {t.thanksForChoose} {siteConfig.name}!</div>*/}
            {/*<div className="order">{t.orderNum}</div>*/}
            {/*<div className="order-id"> #{shoppingCart.orderId}</div>*/}
        </>;

        let moreInfo = <></>;
        switch (orderStatus) {
            // Success design
            case OrderStatuses.PAYMENT_FINISH:
                moreInfo = <>
                    <HiBadgeCheck color="#4dd55d"/>
                    {'תודה רבה, הרישום והתשלום התבצע בהצלחה!'}
                    {/*{t.paymentFinish.info}*/}
                    </>;
                break;
            // Failed design
            case OrderStatuses.PAYMENT_FAILED:
                baseLines = <></>;
                moreInfo = <>
                    {/*<div className="info-red">{t.paymentFail.info1}</div>*/}
                    <div className="info-red">תשלום נכשל</div>
                    <div className="info-fail">
                        תשלום נכשל, פנה לנציג שירות סקיי 360
                        {/*{t.paymentFail.infoFail}*/}
                    </div>
                </>;
                break;
        }

        return <>
            {baseLines}
            {moreInfo}
        </>;
    };

    const getImage = () => {
        switch (orderStatus) {
            case OrderStatuses.PAYMENT_FINISH:
                return <img src="/assets/payment/paymentSuccess.png" />;
            case OrderStatuses.PAYMENT_FAILED:
                return <img src="/assets/payment/paymentFailed.png" />;
        }
    };

  return <div id="order-completion">
      <SpinLoader isLoading={isLoading} onTop={true}>
          <h2 className="title">{getTitle()}</h2>
          <div className="image">{getImage()}</div>
          <div className="info">{getInfo()}</div>
      </SpinLoader>
    </div>;
};

export default PaymentCompletion;
