import React, {useEffect, useState} from 'react';
import './course-header.less';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';

const CourseHeader2 = () => {
  const { header } = useSelector((data: RootState) => data.config.translations?.courseLandingPage );
  const { currentLang } = useSelector((data: RootState) => data.config );
    const [ignore, setIgnore] = useState(false);
    const [isScrolledToTop, setIsScrolledToTop] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleScrollToTop = () => {
        setIsScrolledToTop(window.scrollY === 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScrollToTop);
        return () => {
            window.removeEventListener('scroll', handleScrollToTop);
        };
    }, []);

    const scrollFunction = (id: string) => {
        setIgnore(true);
        setTimeout(() => {
            const myDiv = document.getElementById(id);
            myDiv?.scrollIntoView({ behavior: 'smooth' });
            setTimeout(() => setIgnore(false), 2000);
        }, 100);
    };

    function handleScroll() {
        if (ignore) return;
        const section = searchParams.get("section") ?? "";
        const sectionDom = document.getElementById(section);

        if (sectionDom) {
            scrollFunction(section);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [ignore, location.pathname]);

    useEffect(() => {
       const timer = setTimeout(() => {
            handleScroll();
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

  return (
    <div id="course-header-2">
      <div className="links-wrapper content-layout">
        <div className="links">
          {header.links.map((link, i) => {
            return (
              <Link
                  to={"?section=" + link.url}
                  key={i} className="link"
                    onClick={() => scrollFunction(link.url)}
              >
                {link.text}
              </Link>
            );
          })}
        </div>
        <img src={`/assets/sky_360.png`} className="logo" />
      </div>
        <img src={`/assets/sticker_${currentLang}.png`} className="stiker"/>
        <span className="title">{header.title}</span>
    </div>
  );
};

export default CourseHeader2;
