import * as React from 'react';
import backgroundImage from '../../assets/imgs/beach-background.png';

const title = `כשתיירות
וטכנולוגיה
נפגשים.`;

const Text: React.FC<any> = ({ text, className }) => {
  return (
    <div
      className={`my-5 text-white text-right text-6xl leading-10 ${className}`}
    >
      {text}
    </div>
  );
};

const HeroComponent: React.FC = () => {
  return (
    <div className="z-[-1] relative w-full overflow-hidden flex-col justify-center items-end mix-blend-multiply min-h-[542px] max-md:max-w-full max-md:text-xl max-md:leading-10">
      <img
        src={backgroundImage}
        alt="beach-background"
        className="object-cover absolute inset-0 w-[100%] h-[542px]"
      />
      <section className="flex justify-start items-center mt-[100px]">
        <h1 className="relative">
          <Text text="כשתיירות" className="font-black" />
          <Text text="וטכנולוגיה" className="font-black" />
          <Text text="נפגשים." className="font-light" />
        </h1>
      </section>
    </div>
  );
};

export default HeroComponent;
