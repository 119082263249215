import { createSlice } from '@reduxjs/toolkit';

const initialState: stateInterface = {
  config: {},
  translations:{},
  currentLang: '',
};

interface stateInterface {
  config:any,
  translations:any,
  currentLang: string,
}

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      state.config = action.payload;
    },
    updateLang: (state, action) => {
      state.currentLang = action.payload;
    },
    updateTranslations: (state, action) => {
      state.translations = action.payload;
    },
  },
});

export const { updateConfig, updateTranslations, updateLang } = configSlice.actions;

export default configSlice.reducer;