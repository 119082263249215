import React, {memo, useCallback, useEffect, useState} from 'react';
import {Button, Col, DatePicker, Form, Input, Layout, message, Radio, Row, Select, Space} from "antd";
import "./training-register-form.less";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";
import {AiOutlineArrowDown} from "react-icons/ai";
import locale from 'antd/es/date-picker/locale/he_IL';
import moment from "moment";
import ButtonWithArrow from "../../../../components/Common/ButtonWithArrow/ButtonWithArrow";
import TextArea from "antd/es/input/TextArea";
import SpinLoader from '@skygroup/shared/components/spin-loader/SpinLoader';
import RadioGroupContext from "antd/es/radio/context";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {debug} from "node:util";
import apiClient from '@skygroup/shared/API/apiClient';

const TrainingRegisterForm = ({ courseCycleId, currentCourse}) => {
    const COURSE_LEAD_TYPE_ID = "1";
    const COURSE_LEAD_API = "b2c/lead/create";

    const {payment: translation} = useSelector((data: RootState) => data.config.translations?.trainingLandingPage);
    const [isLoading, setIsLoading] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    const [formMessage, setFormMessage] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    let { aff } = useParams();
    const navigate = useNavigate();

    const [form] = Form.useForm();

    const columnSizeBoxNum = {
        xs: 24,
        md: 6,
    };
    const requiredRule = {required: true, message: translation.required};

    useEffect(() => {

    }, [isLoading, showPayment, formMessage]);

    const createLead = (leadForm) => {
        const formData = new FormData();
        if (currentCourse?.leadType && currentCourse?.leadType !== null) {
            formData.append("lead_type_id", currentCourse.leadType);
        } else {
            formData.append("lead_type_id", COURSE_LEAD_TYPE_ID);
        }
        formData.append("course_cycle", courseCycleId);
        formData.append("affiliate", aff ?? "");

        for (const key in leadForm) {
            formData.append(key, leadForm[key] ? leadForm[key] : '');
        }

        setIsLoading(true);
        apiClient
            .post(COURSE_LEAD_API, formData)
            .then((res) => {
                if (res.data.status == "success") {
                    form.resetFields();
                    if (currentCourse.price > 0) {
                    setFormMessage(translation.form.onSuccessPay);
                        // form.resetFields();
                        setTimeout(() => {

                            window.location.href = location.pathname + "?secret=" + res.data.data.secret;
                        }, 3000);
                    } else {
                        setFormMessage(translation.form.onSuccess);
                    }
                }
            })
            .catch(err => {

            })
            .finally(() => {
                setIsLoading(false);
            });

    };

    return (
        <div id="training-register-form-2">
            <div className="content-layout">
                <Row>
                    <Col xs={24}>
                        <div className="title">{ currentCourse?.page ? currentCourse?.page.title : currentCourse?.title}</div>
                        { currentCourse?.page ?  <div  dangerouslySetInnerHTML={{
                            __html: currentCourse?.page.content,
                        }} /> : null}
                        {currentCourse?.extra_info?.length > 0 ?
                            <div className={"extra-info"}>
                                {currentCourse?.extra_info.map(((a, i) => <div key={i}>{a}</div>))}
                            </div> :
                            ""
                        }
                        {currentCourse?.price > 0 ?
                            <>
                                <div className="course-price">
                                    <span className="price">{currentCourse?.price}</span>
                                    <span className="sign">ש״ח</span>
                                </div>
                                <div className="more-info">
                                    {currentCourse?.price_extra_info?.length > 0 ?
                                        <div className={"price-extra-info"}>
                                            {currentCourse?.price_extra_info.map(((a, i) => <div key={i}>{a}</div>))}
                                        </div> :
                                        ""
                                    }
                                </div>
                            </>
                            :""}
                        <div className="reg-now-title">
                            {currentCourse?.regNowTitle ? currentCourse?.regNowTitle : translation.registerNow}
                        </div>
                        <AiOutlineArrowDown size={60} style={{margin: '0 auto 30px auto'}}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={20} md={14} style={{margin: 'auto'}}>
                        {!showPayment ?
                            <SpinLoader isLoading={isLoading}>
                                <Form
                                    form={form}
                                    layout="inline"
                                    onFinish={createLead}
                                >
                                <Form.Item name="full_name" rules={[requiredRule]}>
                                    <Input placeholder={"* " + translation.form.full_name}/>
                                </Form.Item>
                                <Form.Item name="email" rules={[requiredRule,
                                    {
                                        type: 'email',
                                        message: 'אימייל לא תקין',
                                    }]}>
                                    <Input placeholder={"* " + translation.form.email}/>
                                </Form.Item>
                                <Form.Item name="phone" rules={[requiredRule]}>
                                    <Input placeholder={"* " + translation.form.phone}/>
                                </Form.Item>
                                    {currentCourse?.showAgencyName ?
                                <Form.Item name="agency_name">
                                    <Input placeholder={translation.form.agency_name}/>
                                </Form.Item> : null}
                                    {currentCourse?.showSibsudFields ?
                                        <>
                                            <div className={'field-title'}>בדיקת התאמה לזכאות סבסוד מטעם משרד העבודה</div>
                                            <Form.Item
                                                name="birthdate"
                                            >
                                                <DatePicker
                                                    locale={locale}
                                                    format="MM/DD/YYYY"
                                                    placeholder={translation.form.birthday}
                                                    disabledDate={(current) => {
                                                        // Disable all future dates (after today)
                                                        return current && current > moment().endOf('day');
                                                    }}
                                                    showToday={false}  // Hides the "Today" button
                                                    allowClear  // Allows the user to clear the date selection
                                                />
                                            </Form.Item>
                                            <Form.Item name="salary" rules={[requiredRule]}>
                                                <Input placeholder={"* ממוצע שכר חודשי (ברוטו)" }/>
                                            </Form.Item>
                                            <Form.Item className={"radio-list full-line apply_already"} name="apply_already"   >
                                                <span>האם ביצעת הכשרה ב3 שנים האחרונות  דרך משרד העבודה?</span>
                                                <Radio.Group onChange={ (e) => { form.setFieldValue("apply_already", e.target.value);}}>
                                                    <Radio value={"1"}>כן</Radio>
                                                    <Radio value={"0"}>לא</Radio>
                                                </Radio.Group>
                                            </Form.Item>

                                            <Form.Item className={"radio-list full-line income-type"} name="income_type" >
                                                <span>מעוניין\ת בהכנסה</span>
                                                <Radio.Group onChange={ (e) => { form.setFieldValue("income_type", e.target.value);}}>
                                                    <Radio value={"additional"}>נוספת</Radio>
                                                    <Radio value={"main"}>ראשית</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </> : null}


                                    {currentCourse?.showPikadonFields ?
                                        <>
                                            <div className={'field-title'}>בדיקת זכאות לשימוש בפקדון עבור חיילים משוחררים</div>
                                            <Form.Item
                                                name="birthdate"
                                            >
                                                <DatePicker
                                                    locale={locale}
                                                    format="MM/DD/YYYY"
                                                    placeholder={translation.form.birthday}
                                                    disabledDate={(current) => {
                                                        // Disable all future dates (after today)
                                                        return current && current > moment().endOf('day');
                                                    }}
                                                    showToday={false}  // Hides the "Today" button
                                                    allowClear  // Allows the user to clear the date selection
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="army_finish"
                                            >
                                                <DatePicker
                                                    locale={locale}
                                                    format="MM/DD/YYYY"
                                                    placeholder={'תאריך שחרור'}
                                                    disabledDate={(current) => {
                                                        // Disable all future dates (after today)
                                                        return current && current < moment().endOf('day');
                                                    }}
                                                    // showToday={false}  // Hides the "Today" button
                                                    allowClear  // Allows the user to clear the date selection
                                                />
                                            </Form.Item>
                                            <Form.Item className={"radio-list full-line apply_already"} name="apply_already"   >
                                                <span>האם מימשת את הפיקדון עבורהכשרה מקצועית?</span>
                                                <Radio.Group onChange={ (e) => { form.setFieldValue("apply_already", e.target.value);}}>
                                                    <Radio value={"1"}>כן</Radio>
                                                    <Radio value={"0"}>לא</Radio>
                                                </Radio.Group>
                                            </Form.Item>

                                            <Form.Item className={"radio-list full-line income-type"} name="income_type" >
                                                <span>מעוניין\ת בהכנסה</span>
                                                <Radio.Group onChange={ (e) => { form.setFieldValue("income_type", e.target.value);}}>
                                                    <Radio value={"additional"}>נוספת</Radio>
                                                    <Radio value={"main"}>ראשית</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </> : null}

                                {/*</Form.Item>*/}
                                {/*<Form.Item className={"course-cycles"} name="course_cycle" >*/}
                                {/*    <span>{translation.form.cycleChoose}</span>*/}
                                {/*    <Select onChange={(val) => {form.setFieldValue('course_cycle',val);}}>*/}
                                {/*        {Object.entries(cycles).map(([key, value], index) => (*/}
                                {/*            <Select.Option value={key} key={index}>*/}
                                {/*                {value as string}*/}
                                {/*            </Select.Option>*/}
                                {/*        ))}*/}
                                {/*    </Select>*/}
                                {/*</Form.Item>*/}
                                {/*<Form.Item className={"interested-in"} name="interested" >*/}
                                {/*    <span>{translation.form.radioSelection}</span>*/}
                                {/*    <Radio.Group onChange={ (e) => { form.setFieldValue("interested", e.target.value);}}>*/}
                                {/*        <Radio value={"independent"}>{translation.form.independent}</Radio>*/}
                                {/*        <Radio value={"employee"}>{translation.form.employee}</Radio>*/}
                                {/*    </Radio.Group>*/}
                                {/*</Form.Item>*/}
                                {/*<div className={'title title-med'}> {currentCourse?.title}</div>*/}
                                <ButtonWithArrow text={currentCourse?.price > 0 ? translation.form.actionWithPrice :
                                    (currentCourse?.btnSend ?? translation.form.action) } color="#00264b" action={form.submit}/>
                                {formMessage ? <div className="successMessage">{formMessage}</div> : null}
                            </Form>
                        </SpinLoader> :
                        <div>Payment Form</div>
                        }
                    </Col>
                </Row>
            </div>
        </div>
    );
};



export default memo(TrainingRegisterForm);
