import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import './experts-slider.less';
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";

const experts = [
  'family_break.png',
  'mona_tours.png',
  'arkia.png',
  'alp.png',
  'lord_tickets.png',
  'sky_360.png',
  'family_break.png',
  'mona_tours.png',
  'arkia.png',
  'alp.png',
  'lord_tickets.png',
  'sky_360.png',
];

const ExpertsSlider = () => {
    const { courseLandingPage:t } = useSelector((data: RootState) => data.config.translations );

  return (
    <div id="experts-slider" className={"content-layout"}>
        <div className="title">{t.courseExperts.titleSlider}</div>
      <Splide
        options={{
          autoplay: true,
          interval: 1500,
          pauseOnHover: true,
          type: 'loop',
          drag: 'free',
          autoWidth: true,
          pagination: false,
          arrows: false,
          gap: '50px',
          direction: 'rtl',
        }}
      >
        {experts.map((expert) => {
          return (
            <SplideSlide key={expert}>
              <img src={`/assets/${expert}`} className="img" />
            </SplideSlide>
          );
        })}
      </Splide>
    </div>
  );
};

export default ExpertsSlider;
