import React, {useEffect, useState} from 'react';
import './webinar-header.less';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';

const WebinarHeader = ({currentCourse}) => {
  const { header, title: titleT } = useSelector((data: RootState) => data.config.translations?.webinarLandingPage );
  const { currentLang } = useSelector((data: RootState) => data.config );
    const [ignore, setIgnore] = useState(false);
    const [isScrolledToTop, setIsScrolledToTop] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleScrollToTop = () => {
        setIsScrolledToTop(window.scrollY === 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScrollToTop);
        return () => {
            window.removeEventListener('scroll', handleScrollToTop);
        };
    }, []);

    const scrollFunction = (id: string) => {
        setIgnore(true);
        setTimeout(() => {
            const myDiv = document.getElementById(id);
            myDiv?.scrollIntoView({ behavior: 'smooth' });
            setTimeout(() => setIgnore(false), 2000);
        }, 100);
    };

    function handleScroll() {
        if (ignore) return;
        const section = searchParams.get("section") ?? "";
        const sectionDom = document.getElementById(section);

        if (sectionDom) {
            scrollFunction(section);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [ignore, location.pathname]);

    useEffect(() => {
       const timer = setTimeout(() => {
            handleScroll();
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

  return (
      <div id="webinar-header">
          <div className="links-wrapper content-layout">
              <a href={"https://www.sky360.co.il"} target={"_blank"} rel="noreferrer"><img src={`/assets/sky_360.png`} className="logo"/></a>
              {currentCourse?.showOrensLogo ? <img src={'/assets/leads/orens.png'} className={'aff-logo'} /> : ''}
              {currentCourse?.showSVLogo ? <img src={'/assets/leads/sv.jpg'} className={'aff-logo'} /> : ''}
              {currentCourse?.showWorkMinLogo ? <img src={'/assets/leads/work-min.png'} className={'aff-logo'} /> : ''}
              {currentCourse?.affiliate?.logo ? <img src={currentCourse?.affiliate?.logo} className={'aff-logo'} /> : ''}
          </div>
          {/*<div className={'back-training'}></div>*/}
      </div>
  );
};

export default WebinarHeader;
