import React, { memo } from 'react';
import {Col, Layout, Row} from "antd";
import "./course-info.less";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/store";

const TrainingInfo = ({currentCourse}) => {
    const { sections:tranSections,courseDetails: tranDetails, forWho:tanForWho } = useSelector((data:RootState) => data.config.translations?.webinarLandingPage);

    const columnSizeBoxNum = {
        xs: 24,
        md: 6,
    };
    const columnSizeDesc = {
        xs: 24,
        md: 10,
    };
    return (
        <div id="course-info">
            <div className="content-layout">
                <Row id="course-details">
                    <Col id="course-when" xs={24} md={6}>
                        <div className="detail-box">
                            <div className="icon"></div>
                            <div className="title">{tranDetails.when.title}</div>
                            <div className="content">
                                <ul>
                                    {currentCourse?.days.map((day,index) => <li key={index}>{day}</li>)}
                                </ul>
                                <div className={"hour"}></div>
                            </div>
                        </div>
                    </Col>
                    <Col id="course-structure" xs={24} md={12}>
                        <div className="detail-box">
                            <div className="icon"></div>
                            <div className="title">{tranDetails.structure.title}</div>
                            <div className="semi-title">{tranDetails.structure.semiTitle}</div>
                            <div className="content">
                                <ul>
                                    {Object.keys(tranDetails.structure.content).map((key, index) => {
                                        return (<li key={index}>{tranDetails.structure.content[key]}</li>);
                                    })}
                                </ul>
                                <div className={"bonus"}>
                                    <div className={"title"}>{tranDetails.structure.bonus.title}</div>
                                    <div className={"content"}>{tranDetails.structure.bonus.content1}</div>
                                    <div className={"content"}>{tranDetails.structure.bonus.content2}</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col id="course-duty" xs={24} md={6}>
                        <div className="detail-box">
                            <div className="icon"></div>
                            <div className="title">{tanForWho.title}</div>
                            <ul>
                                {Object.keys(tanForWho.content).map((key, index) => {
                                    return (<li key={index}>{tanForWho.content[key]}</li>);
                                })}
                            </ul>
                        </div>
                    </Col>
                    {/*<Col id="course-duty" xs={24} md={6}>*/}
                    {/*    <div className="detail-box">*/}
                    {/*        <div className="icon"></div>*/}
                    {/*        <div className="title">{tranDetails.duty.title}</div>*/}
                    {/*        <div className="content">{tranDetails.duty.content}</div>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                </Row>

                {/*<Row id="course-who">*/}
                {/*    <Col md={12} xs={24}>*/}
                {/*        <div className="title">{tanForWho.title}</div>*/}
                {/*    </Col>*/}
                {/*    <Col md={12}>*/}
                {/*        <div className="content">*/}
                {/*            <ul>*/}
                {/*            {Object.keys(tanForWho.content).map((key, index) => {*/}
                {/*                return (<li key={index}>{tanForWho.content[key]}</li>);*/}
                {/*            })}*/}
                {/*            </ul>*/}
                {/*        </div>*/}
                {/*    </Col>*/}
                {/*</Row>*/}


            </div>
        </div>
    );
}
export default memo(TrainingInfo);
