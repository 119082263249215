import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {loadPaymentForm} from "./trainingPaymentApi";
import SpinLoader from '@skygroup/shared/components/spin-loader/SpinLoader';
import {RootState} from "../../../../store/store";
import './training-payment-form.less';
import PaymentCompletion from "./PaymentCompletion";
interface Props {
  secret: string;
}

const TrainingPaymentForm = ({ secret }: Props) => {
  const dispatch = useDispatch();
  const { paymentForm: t } = useSelector((data: RootState) => data.config.translations?.courseLandingPage );

  // local state
  const [isLoading, setIsLoading] = useState(false);
  const [showTransactionMessage, setShowTransactionMessage] = useState(false);
  const [transactionId, setTransactionId] = useState(0);
  const [paymentGWData, setPaymentGWData]: [any, any] = useState({});
  const [showSplitCards, setShowSplitCards] = useState(false);
  const [splitCardsNum, setSplitCardsNum] = useState(1);
  const [currentCard, setCurrentCard] = useState(1);
  const [formApiError, setApiError] = useState<any>(null);
  const [formApiSuccess, setApiSuccess] = useState<any>(null);

  const maxPaymentRetries = 4;
  // @ts-ignore
  const paymentRetries = window.globalPaymentRetries;

  useEffect(() => {
    if (showSplitCards === false) {
      // @ts-ignore
      window.globalPaymentRetries = 1;
      loadForm();
    }
  }, [currentCard, showSplitCards]);

  // useEffect(() => {
  //     if (paymentRetries > 1) {
  //         debugger;
  //         loadPaymentForm();
  //     }
  // }, [paymentRetries]);

  const handlePaymentMessageFromIframe = (event: any) => {
    var response = event.data;
    let procceed = false;
    if (response.status) {
      if (response.status == 'success') {
         procceed = true;
      } else {
        // error - need to check if we got max retries
        // @ts-ignore
        if (window.globalPaymentRetries >= maxPaymentRetries) {
          procceed = true;
        } else {
          // @ts-ignore
          window.globalPaymentRetries++;
          loadForm();
        }
      }

      if (procceed) {
        const transaction_id =
          response.status == 'success'
            ? response.data.transaction_id
            : response.data.message.transaction_id;

        setTransactionId(transaction_id);
        setShowTransactionMessage(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', handlePaymentMessageFromIframe);
    return () => {
      window.removeEventListener('message', handlePaymentMessageFromIframe);
    };
  }, []);

  const loadForm = async () => {
    try {
      const formData = {
        secret: secret,
        splitCards: splitCardsNum,
        currentCard: currentCard,
      };
      // this is update action
      const res = await loadPaymentForm(formData, setIsLoading, secret);
      if (res.data.status === 'success') {
        setApiError(null);
        setPaymentGWData(res.data.data);
      } else {
        // error return from server
        setApiError(res.data.data);
      }
    } catch (error: any) {
      setApiError(error);
    }
  };

  if (formApiError !== null) {
    return <>{JSON.stringify(formApiError)}</>;
  }

  return (
    <div id="course-payment-form">
      {showTransactionMessage ? (
        <PaymentCompletion customerSecret={secret} transactionId={transactionId} />
      ) : (
        <SpinLoader isLoading={isLoading} onTop={true}>
          <div >
             <h1>{` ${paymentGWData?.leadInfo?.full_name} - #${paymentGWData?.leadInfo?.id} - ${paymentGWData?.leadInfo?.course_cycle}`}</h1>
            <h2>
              {t.title}
            </h2>
            <div
              className={`tp-payment-gateway tp-payment-gateway-${paymentGWData.settings?.payby}`}
            >
              {paymentRetries > 1 ? (
                <h3>
                  {t.errorMessageToRetry.replace(
                    '{retryNum}',
                    paymentRetries.toString()
                  )}
                </h3>
              ) : null}
              <div className="iframe-form">
                <div
                  dangerouslySetInnerHTML={{
                    __html: paymentGWData.payForm?.replace('\\', ''),
                  }}
                />
              </div>
            </div>
          </div>
          {/* )} */}
        </SpinLoader>
      )}
    </div>
  );
};

export default TrainingPaymentForm;
