import React from 'react';
import translationsHe from '../../translations/translationsHe';
import './footer.less';
// @ts-ignore
import logo from './assets/footerIcon.png';

const footer = translationsHe.data.translation.productPage.footer;
const Footer = () => {
  return (
    <div className="footer">
      <img src={logo} className="logo" />
      <div className="text">{footer.text}</div>
    </div>
  );
};

export default Footer;
