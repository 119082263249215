import React from 'react';
import './course-finish-successfully.less';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';

const CourseFinishSuccessfully = () => {
  const { finishSuccessfully } = useSelector(
    (data: RootState) => data.config.translations?.webinarLandingPage
  );
  return (
      <div id="finish-successfully">
          <div className="content-layout">
              <div className="certificate-icon"></div>

              <h3 className="title">{finishSuccessfully.title}</h3>

              <div className="benefits-wrapper">
                  {finishSuccessfully.benefits.map((benefit) => {
                      return (
                          <div key={benefit.title} className="benefit">
                              <img src={`/assets/${benefit.img}`}/>
                              <span className="benefit-title" dangerouslySetInnerHTML={{__html:benefit.title}}>
                              </span>
                          </div>
                      );
                  })}
              </div>
          </div>
      </div>
  );
};

export default CourseFinishSuccessfully;
