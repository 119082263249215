import * as React from 'react';
import img from '../../assets/imgs/technology-frame.png';

interface HeadingProps {
  text: string;
  className?: string;
}

const Heading: React.FC<HeadingProps> = ({ text, className }) => {
  return (
    <div
      className={`text-2xl font-extrabold text-white leading-9 max-md:text-xl ${className}`}
    >
      {text}
    </div>
  );
};

interface SubheadingProps {
  text: string;
  className?: string;
}

const Subheading: React.FC<SubheadingProps> = ({ text, className }) => {
  return (
    <div
      className={`mt-5 text-2xl leading-6 text-sky-300 max-md:max-w-full max-md:text-xl ${className}`}
    >
      {text}
    </div>
  );
};

const TechnologyComponent: React.FC = () => {
  return (
    <section
      id="technology"
      className="!overflow-visible relative flex justify-start items-start self-start mt-32 text-right bg-slate-800 rounded-[10px] max-md:px-5 max-md:mt-10 max-md:max-w-full"
    >
      <img
        loading="lazy"
        src={img}
        alt={'technology img'}
        className="absolute right-0 top-[50%] translate-y-[-50%] h-[400px] max-md:hidden z-[101]"
      />
      <div className="flex flex-col my-auto py-[70px] md:mr-[450px] max-md:max-w-full max-md:py-5">
        <Heading text="טכנולוגיה" className="self-start" />
        <Subheading text="הכירו את המערכות החדשניות של עולם התיירות" />
      </div>
    </section>
  );
};

export default TechnologyComponent;
