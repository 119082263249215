import * as React from 'react';
import universalAirLogo from '../../assets/imgs/Universal-Air-Logo.png';
import sapLogo from '../../assets/imgs/sap-logo-svg.png';
import sohoLogo from '../../assets/imgs/soho-logo.png';
import horizontalGreenBlackLogo from '../../assets/imgs/horizontal-green-black-logo.png';
import familybreakLogo from '../../assets/imgs/family-break-logo.png';

interface ImageProps {
  src: string;
  alt: string;
  className?: string;
}

const Image: React.FC<ImageProps> = ({ src, alt, className }) => {
  return <img loading="lazy" src={src} alt={alt} className={className} />;
};

const images = [
  {
    src: universalAirLogo,
    alt: 'Image 1',
    className: 'shrink-0 mt-9 max-w-full aspect-[4.17] w-[262px]',
  },
  {
    src: sapLogo,
    alt: 'Image 2',
    className: 'shrink-0 mt-9 w-32 max-w-full aspect-[2]',
  },
  {
    src: sohoLogo,
    alt: 'Image 3',
    className: 'mt-9 aspect-[6.25] w-[389px]',
  },
  {
    src: horizontalGreenBlackLogo,
    alt: 'Image 4',
    className: 'shrink-0 max-w-full aspect-[1.92] w-[218px]',
  },
  {
    src: familybreakLogo,
    alt: 'Image 5',
    className: 'shrink-0 mt-4 max-w-full aspect-[2.56] w-[243px]',
  },
];

const ClientsListComponent: React.FC = () => {
  return (
    <div id={'clients'}>
      <h1 className="mt-24 text-5xl font-extrabold text-center leading-[27px] text-slate-800 max-md:mt-10 max-md:max-w-full max-md:text-4xl">
        בין לקוחותינו
      </h1>
      <div className="relative flex gap-30 overflow-x-hidden max-sm:justify-center">
        <div className="overflow-x-scroll max-sm:flex-col max-sm:overflow-x-visible whitespace-nowrap flex gap-20 max-sm:gap-4 justify-between max-sm:justify-start items-end max-sm:items-center self-stretch mt-12 max-sm:my-2 max-w-[100%]">
          {images.map((image, index) => (
            <Image
              key={'marquee_' + index}
              src={image.src}
              alt={image.alt}
              className={image.className}
            />
          ))}
          {/* {images.map((image, index) => (
            <Image
              key={'marquee_' + index}
              src={image.src}
              alt={image.alt}
              className={image.className}
            />
          ))} */}
        </div>
      </div>
    </div>
  );
};

export default ClientsListComponent;
