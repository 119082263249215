import * as React from 'react';
import cardsImg from '../../assets/imgs/dashboard-template-cards.png';
import bgImg from '../../assets/imgs/promotion-card-background.png';

interface TextBlockProps {
  title: any;
  items: string[];
  buttonText: string;
  registerUrl: string;
}

const TextBlock: React.FC<TextBlockProps> = ({
  title,
  items,
  buttonText,
  registerUrl,
}) => {
  return (
    <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
      <div className="flex relative flex-col self-stretch my-auto font-extrabold text-white max-md:mr-[20px] max-md:mt-10 max-md:max-w-full">
        <h2 className="text-white self-start text-2xl leading-20 text-right max-md:max-w-full max-md:text-2xl max-md:leading-10">
          {title}
        </h2>
        <div className="flex justify-end flex-row-reverse max-md:flex-col max-md:items-center gap-5 mt-3 text-base leading-2 max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
          <a
            onClick={() => window.open(registerUrl, '_blank')}
            className="justify-center p-2 mt-2.5 text-xl text-black bg-sky-300 rounded-xl max-md:px-5"
          >
            {buttonText}
          </a>
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <div className="my-auto text-right">{item}</div>
              {index !== items.length - 1 && (
                <div className="shrink-0 w-0.5 bg-sky-300 h-[100%] max-md:w-[100%] max-md:h-0.5" />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default function SystemPromotionCardComponent({
  registerUrl,
}: {
  registerUrl: string;
}) {
  return (
    <section className="flex overflow-hidden relative flex-col justify-center items-end px-4 mt-24 min-h-[250px] max-md:pl-5 max-md:mt-10 !overflow-visible">
      <img
        loading="lazy"
        src={bgImg}
        alt=""
        className="object-cover absolute inset-0 size-full rounded-[50px]"
      />
      <div className="relative w-[100%] z-10 max-md:mb-2.5 max-md:max-w-full">
        <div className="flex justify-center gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col ml-10 w-3/12 max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              src={cardsImg}
              alt="card-image"
              className="absolute top-[50%] translate-y-[-50%] translate-x-[25%] right-0 h-[400px] max-md:hidden z-[101]"
            />
          </div>
          <TextBlock
            {...{
              title: (
                <>
                  {' '}
                  {`נסו את המערכות הכי מתקדמות`}
                  <br />
                  {`בעולם התיירות בקליק אחד.`}
                </>
              ),
              items: ['SKY 360', 'AGENT 360 PRO', 'AGENT 360'],
              buttonText: 'לרישום חינם',
              registerUrl: registerUrl,
            }}
            registerUrl={registerUrl}
          />
        </div>
      </div>
    </section>
  );
}