import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';;
import { RootState } from '../store/store';
import translationsHe from '../translations/translationsHe';
import translationsAr from '../translations/translationsAr';
import mergeDeep from '@skygroup/shared/utils/mergeDeep';
import {updateLang, updateTranslations} from "../store/config";

interface Props {
  siteConfig?: any;
}

export default function useSiteLang({ siteConfig }: Props) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentLang } = useSelector((data: RootState) => data.config);
  const [isTranLoaded, setIsTranLoaded] = useState(false);

  const [selectedLang, setSelectedLang] = useState<string | null>(null);
  const [direction, setDirection] = useState<'ltr' | 'rtl'>("ltr");

  const getTranslations = useCallback(async () => {
    // make sure not proceed if we don't have both (selectedLang & siteConfig) - they loaded in async
    if (!selectedLang || !siteConfig) {
      return;
    }
    console.log("loading translation....", selectedLang);
    // const translations = response.data.data.translation;
    dispatch(updateLang(selectedLang));
    if (selectedLang == 'ar') {
      dispatch(updateTranslations(mergeDeep(translationsHe.data.translation, translationsAr.data.translation)));
    } else {
      dispatch(updateTranslations(translationsHe.data.translation));
    }
    const matchedConfigLang = siteConfig?.languages.find(
      (item) => item?.code === selectedLang
    );
    if (matchedConfigLang?.dir) {
      setDirection(matchedConfigLang.dir);
    }
    setIsTranLoaded(true);
  }, [dispatch, selectedLang, siteConfig]);

  useEffect(() => {
    // set default val for selectedLang once config is loaded
    const langFromParam =  searchParams.get('lang');
    const isMatched = siteConfig?.languages.find((item) => item.code === langFromParam);
    if (langFromParam && isMatched?.code) {
      console.log("Set from url param", langFromParam);
      localStorage.setItem('lang', langFromParam);
      setSelectedLang(langFromParam);
    } else if (localStorage.getItem('lang')) {
      console.log("Set from localstorage", localStorage.getItem('lang'))
      setSelectedLang(localStorage.getItem('lang'));
    } else if (siteConfig?.defaultLang) {
      console.log("Set from default", siteConfig?.defaultLang)
      setSelectedLang(siteConfig.defaultLang);
    }
  }, [searchParams, siteConfig]);

  useEffect(() => {
    // user manually updated url
    const langParam = searchParams.get('lang');
    const isMatched = siteConfig?.languages.find((item) => item.code === langParam);
    if (langParam === null || !isMatched) {
      return;
    }
    localStorage.setItem('lang', langParam);
    setSelectedLang(langParam);
  }, [searchParams, siteConfig?.languages]);

  // init trans and config
  useEffect(() => {
    getTranslations();
  }, [selectedLang, siteConfig]);

  useEffect(() => {
    // currLang updated from lang dropdown
    if (currentLang) {
      console.log("Set from selected lang from dropdown", currentLang);
      setSelectedLang(currentLang);
    }
  }, [currentLang]);

  return { isTranLoaded, direction };
}
